import { Color, ColorTheme, Gradient } from '@chic-loyalty/ui';
import { SubscriptionOrderStatus, SubscriptionDeliveryStatus, SubscriptionPaymentStatus } from '@chic/enums';

export const orderStatusTheme: Record<SubscriptionOrderStatus, ColorTheme> = {
  [SubscriptionOrderStatus.Complete]: { font: Color.ICNavy, background: Color.ICGreen },
  [SubscriptionOrderStatus.Failed]: { font: Color.ICWhite100, background: Color.ICRed200 },
  [SubscriptionOrderStatus.OnDelivery]: { font: Color.ICBlack400, background: Color.ICYellow100 },
  [SubscriptionOrderStatus.Pending]: { font: Color.ICNavy, background: Color.ICBlue },
  [SubscriptionOrderStatus.RefundRequest]: { font: Color.ICGray400, background: Color.ICBlack100 },
  [SubscriptionOrderStatus.RefundRequestAccepted]: {
    font: Color.ICWhite100,
    border: Color.ICBlack100,
    background: Gradient.Dark,
  },
  [SubscriptionOrderStatus.Refunded]: { font: Color.ICWhite100, background: Color.ICBlueDark },
  [SubscriptionOrderStatus.WaitingForPayment]: { font: Color.ICBlack400, background: Color.ICWhite100 },
};

export const mobileOrderStatusColor: Record<SubscriptionOrderStatus, Color> = {
  [SubscriptionOrderStatus.Complete]: Color.ICGreen,
  [SubscriptionOrderStatus.Failed]: Color.ICRed100,
  [SubscriptionOrderStatus.OnDelivery]: Color.ICYellow100,
  [SubscriptionOrderStatus.Pending]: Color.ICBlue,
  [SubscriptionOrderStatus.RefundRequest]: Color.ICGray500,
  [SubscriptionOrderStatus.RefundRequestAccepted]: Color.ICBlack100,
  [SubscriptionOrderStatus.Refunded]: Color.ICNavy,
  [SubscriptionOrderStatus.WaitingForPayment]: Color.ICWhite100,
};

export const deliveryStatusTheme: Record<SubscriptionDeliveryStatus, ColorTheme> = {
  [SubscriptionDeliveryStatus.Collected]: { font: Color.ICNavy, background: Color.ICBlue },
  [SubscriptionDeliveryStatus.Completed]: { font: Color.ICNavy, background: Color.ICGreen },
  [SubscriptionDeliveryStatus.Expired]: { font: Color.ICWhite100, background: Color.ICRed200 },
  [SubscriptionDeliveryStatus.InTransit]: { font: Color.ICNavy, background: Color.ICBlue },
  [SubscriptionDeliveryStatus.Pending]: { font: Color.ICBlack400, background: Color.ICYellow100 },
  [SubscriptionDeliveryStatus.Resend]: { font: Color.ICBlack400, background: Color.ICYellow100 },
  [SubscriptionDeliveryStatus.Returned]: { font: Color.ICGray400, background: Color.ICBlack100 },
  [SubscriptionDeliveryStatus.SendBack]: { font: Color.ICBlack400, background: Color.ICYellow100 },
  [SubscriptionDeliveryStatus.Shipped]: { font: Color.ICBlack400, background: Color.ICYellow100 },
  [SubscriptionDeliveryStatus.WaitingForSurcharge]: { font: Color.ICBlack400, background: Color.ICYellow100 },
  [SubscriptionDeliveryStatus.WaitingForUpdate]: { font: Color.ICBlack400, background: Color.ICYellow100 },
};

export const paymentStatusTheme: Record<SubscriptionPaymentStatus, ColorTheme> = {
  [SubscriptionPaymentStatus.Failed]: { font: Color.ICWhite100, background: Color.ICRed200 },
  [SubscriptionPaymentStatus.Pending]: { font: Color.ICBlack400, background: Color.ICYellow100 },
  [SubscriptionPaymentStatus.Refunded]: { font: Color.ICNavy, background: Color.ICBlue },
  [SubscriptionPaymentStatus.Rejected]: { font: Color.ICWhite100, background: Color.ICRed200 },
  [SubscriptionPaymentStatus.Success]: { font: Color.ICNavy, background: Color.ICGreen },
};
