import { 
  UseState, 
  AddFileSettings, 
  UseNotifications, 
  useNotifications, 
  FileSettingsBox, 
  AddFileInitialData, 
  AddFileTheme, 
} from '@chic-loyalty/ui';
import { uploadAsset, createNewAsset, createNewScreen, getScreenDetails, getAssetDetails, editScreen, editAsset } from '@chic/api';
import { FileType, AdNodeType, UploadAssetType, AdScreenType, FileTypeExtended, AdAssetPropertyType } from '@chic/enums';
import { 
  UploadedAssetDetails, 
  FrontendApiError, 
  AssetDetails, 
  AdsNode, 
  ScreenDetails, 
  UsePlaylistAnimation, 
  UseAnimationsContext,
  ScreenNodeDetails, 
} from '@chic/models';
import { useEffect, useMemo, useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { useAnimationsContext } from './useAnimationsContext.hook';

export const usePlaylistAnimation: () => UsePlaylistAnimation = (): UsePlaylistAnimation => {
  const { t }: TransProps<never> = useTranslation();
  const { addToast }: UseNotifications = useNotifications();
  const { animationData, saveAnimationStateValues }: UseAnimationsContext = useAnimationsContext();
  const [uploadedCover, setUploadedCover]: UseState<UploadedAssetDetails | null> 
    = useState<UploadedAssetDetails | null>(null);
  const [resetUploadedCover, setResetUploadedCover]: UseState<boolean> = useState<boolean>(false);
  const [uploadCoverError, setUploadCoverError]: UseState<string> = useState<string>('');
  const [uploadedFile, setUploadedFile]: UseState<UploadedAssetDetails | null> 
    = useState<UploadedAssetDetails | null>(null);
  const [resetUploadedFile, setResetUploadedFile]: UseState<boolean> = useState<boolean>(false);
  const [uploadFileError, setUploadFileError]: UseState<string> = useState<string>('');
  const [savedPlaylistScreens, setSavedPlaylistScreens]: UseState<ScreenDetails[]> = useState<ScreenDetails[]>([]);
  const [savedPlaylistThumbnails, setSavedPlaylistThumbnails]: UseState<Map<string, string>> 
    = useState<Map<string, string>>(new Map<string, string>());
  const [playlistElementSettings, setPlaylistElementSettings]: UseState<AddFileSettings | null> = useState<AddFileSettings | null>(null);
  const [screenToEdit, setScreenToEdit]: UseState<ScreenDetails | null> = useState<ScreenDetails | null>(null);
  const [coverAssetDetails, setCoverAssetDetails]: UseState<AssetDetails | null> = useState<AssetDetails | null>(null);
  const [videoAssetDetails, setVideoAssetDetails]: UseState<AssetDetails | null> = useState<AssetDetails | null>(null);

  const playlistRequirements: Record<string, string[]> = useMemo(
    (): Record<string, string[]> => {
      return {
        [AddFileTheme.TabletButton]: [
          t('chic.management.usePlaylistAnimation.requirements.tabletButton.formats'),
          t('chic.management.usePlaylistAnimation.requirements.tabletButton.maxSize'),
          t('chic.management.usePlaylistAnimation.requirements.tabletButton.resolution'),
        ],
        [AddFileTheme.TabletAd]: [
          t('chic.management.usePlaylistAnimation.requirements.tabletAnimation.formats'),
          t('chic.management.usePlaylistAnimation.requirements.tabletAnimation.maxSize'),
          t('chic.management.usePlaylistAnimation.requirements.tabletAnimation.resolution'),
        ],
      };
    },
    [],
  );

  const playlistAcceptedFilesTypes: Record<string, Record<string, string[]>> = useMemo(
    (): Record<string, Record<string, string[]>> => {
      return {
        [AddFileTheme.TabletButton]: {
          ['image/jpeg']: [],
          ['image/png']: [],
        },
        [AddFileTheme.TabletAd]: { ['video/mp4']: [] },
      };
    },
    [],
  );

  useEffect(
    (): void => {
      if (animationData.playlistScreens?.length) {
        setSavedPlaylistScreens(animationData.playlistScreens);
        const thumbnailsMapClone: Map<string, string> = new Map(); 
        animationData.playlistScreens.forEach((playlistElement: ScreenDetails): void => {
          // TODO: fix when unions will be ready
          const coverNode: ScreenNodeDetails = playlistElement.nodes
            .find((node: ScreenDetails | ScreenNodeDetails): boolean => node.type === FileTypeExtended.Image) as ScreenNodeDetails;
          thumbnailsMapClone.set(playlistElement.id, coverNode.value);
        });
        setSavedPlaylistThumbnails(thumbnailsMapClone);
      }
    },
    [animationData],
  );

  const onChangePlaylistElementSettings: (settings: AddFileSettings) => void = (settings: AddFileSettings): void => {
    setPlaylistElementSettings(settings);
  };

  const onAddNewPlaylistItem: () => void = (): void => {
    setResetUploadedCover(true);
    setResetUploadedFile(true);
    setScreenToEdit(null);

    setTimeout(
      (): void => {
        setResetUploadedCover(false);
        setResetUploadedFile(false);
      },
      1000,
    );
  };

  const onAddPlaylistCover: (file: File) => Promise<void> = (file: File): Promise<void> => {
    setUploadCoverError('');
    return new Promise((resolve: () => void, reject: () => void): void => {
      uploadAsset({ 
        file, 
        type: UploadAssetType.TabletButton,
      })
        .then((data: UploadedAssetDetails): void => {
          setUploadedCover(data);
          setResetUploadedCover(false);
          resolve();
          addToast({ content: t('chic.management.usePlaylistAnimations.onAddPlaylistCover.success') });
        })
        .catch((error: FrontendApiError): void => {
          setUploadCoverError(error.firstErrorTranslated ?? t('chic.management.usePlaylistAnimations.onAddPlaylistCover.error'));
          addToast({ content: t('chic.management.usePlaylistAnimations.onAddPlaylistCover.error') });
          reject();
        });
    });
  };

  const onAddPlaylistFile: (file: File) => Promise<void> = (file: File): Promise<void> => {
    setUploadFileError('');
    return new Promise((resolve: () => void, reject: () => void): void => {
      uploadAsset({ 
        file, 
        type: UploadAssetType.TabletAnimation,
      })
        .then((data: UploadedAssetDetails): void => {
          setUploadedFile(data);
          setResetUploadedFile(false);
          resolve();
          addToast({ content: t('chic.management.usePlaylistAnimations.onAddPlaylistFile.success') });
        })
        .catch((error: FrontendApiError): void => {
          setUploadFileError(error.firstErrorTranslated ?? t('chic.management.usePlaylistAnimations.onAddPlaylistFile.error'));
          addToast({ content: t('chic.management.usePlaylistAnimations.onAddPlaylistFile.error') });
          reject();
        });
    });
  };

  const onSavePlaylistElement: () => Promise<void> = (): Promise<void> => {
    if (screenToEdit) {
      // TODO: fix when unions will be ready
      const coverNode: ScreenNodeDetails = screenToEdit.nodes
        .find((node: ScreenDetails | ScreenNodeDetails): boolean => node.type === FileTypeExtended.Image) as ScreenNodeDetails;
      const videoNode: ScreenNodeDetails = screenToEdit.nodes
        .find((node: ScreenDetails | ScreenNodeDetails): boolean => node.type === FileTypeExtended.Video) as ScreenNodeDetails;
      const textNode: ScreenNodeDetails = screenToEdit.nodes
        .find((node: ScreenDetails | ScreenNodeDetails): boolean => node.type === FileTypeExtended.Text) as ScreenNodeDetails;
      let newCoverNode: AssetDetails | undefined;
      let newVideoNode: AssetDetails | undefined;
      let newTextNode: AssetDetails | undefined;
      let coverAsset: AssetDetails | undefined;

      if (coverNode && videoNode && textNode) {
        const coverAssetEdit: Promise<void> | undefined = uploadedCover 
          ? editAsset(coverNode.id, {
            type: uploadedCover.type,
            name: uploadedCover.originalFilename,
            value: uploadedCover.path,
            properties: coverNode.properties,
          })
            .then((coverData: AssetDetails): void => {
              newCoverNode = coverData;
              coverAsset = coverData;
            }) 
          : undefined;

        const videoAssetEdit: Promise<void> | undefined = uploadedFile 
          ? editAsset(videoNode.id, {
            type: uploadedFile.type,
            name: uploadedFile.originalFilename,
            value: uploadedFile.path,
            properties: videoNode.properties,
          })
            .then((videoData: AssetDetails): void => {
              newVideoNode = videoData;
            })
          : undefined;

        const textAssetEdit: Promise<void> | undefined = playlistElementSettings?.text?.length ? editAsset(textNode.id, {
          type: FileType.Text,
          name: textNode.name,
          value: playlistElementSettings.text,
          properties: textNode.properties,
        })
          .then((textData: AssetDetails): void => {
            newTextNode = textData;
          })
          : undefined;

        return Promise.all([coverAssetEdit, videoAssetEdit, textAssetEdit])
          .then((): void => {
            const assetsIds: string[] = [
              coverAssetEdit && newCoverNode ? newCoverNode?.id : coverNode.id,
              videoAssetEdit && newVideoNode ? newVideoNode?.id : videoNode.id,
              textAssetEdit && newTextNode ? newTextNode?.id : textNode.id,
            ];
            editScreen(screenToEdit.id, {
              type: AdScreenType.PlayListElement,
              name: screenToEdit.name,
              nodes: assetsIds.map((assetId: string): AdsNode => ({
                id: assetId,
                type: AdNodeType.Asset,
              })),
            })
              .then((screenDetails: ScreenDetails): void => {
                setSavedPlaylistScreens(
                  (prev: ScreenDetails[]): ScreenDetails[] => prev
                    .map((screen: ScreenDetails): ScreenDetails => screen.id === screenToEdit.id ? { ...screenDetails } : screen),
                );
                const thumbnailsMapClone: Map<string, string> = new Map(savedPlaylistThumbnails); 
                if (coverAsset) {
                  thumbnailsMapClone.set(screenDetails.id, coverAsset.value);
                }
                setSavedPlaylistThumbnails(thumbnailsMapClone);
                saveAnimationStateValues({ 
                  playlistScreens: savedPlaylistScreens
                    .map((screen: ScreenDetails): ScreenDetails => screen.id === screenToEdit.id 
                      ? { ...screenDetails } 
                      : screen,
                    ), 
                });
                setScreenToEdit(null);
                addToast({ content: t('chic.management.usePlaylistAnimations.onEditPlaylistScreen.success') });
              });
          });
      } else {
        return Promise.resolve();
      }
    } else if (uploadedCover && uploadedFile && !!playlistElementSettings?.text) {
      const savedPlaylistElements: AssetDetails[] = [];
      let coverAsset: AssetDetails | undefined;

      const coverAssetRequest: Promise<void> = createNewAsset({
        type: uploadedCover.type,
        name: uploadedCover.originalFilename,
        value: uploadedCover.path,
        id: null,
        properties: [],
      })
        .then((coverData: AssetDetails): void => {
          savedPlaylistElements.push(coverData);
          coverAsset = coverData;
        });

      const fileAssetRequest: Promise<void> = createNewAsset({
        type: uploadedFile.type,
        name: uploadedFile.originalFilename,
        value: uploadedFile.path,
        id: null,
        properties: [
          {
            type: AdAssetPropertyType.Duration,
            value: String(uploadedFile.duration),
          },
        ],
      })
        .then((fileData: AssetDetails): void => {
          savedPlaylistElements.push(fileData);
        });

      const textAssetRequest: Promise<void> = createNewAsset({
        type: FileType.Text,
        name: `Text - ${uploadedCover.originalFilename}`,
        value: playlistElementSettings.text,
        id: null,
        properties: [],
      })
        .then((textData: AssetDetails): void => {
          savedPlaylistElements.push(textData);
        });

      return Promise.all([coverAssetRequest, fileAssetRequest, textAssetRequest])
        .then((): void => {
          if (!playlistElementSettings?.text) {
            return;
          }
            
          createNewScreen({
            type: AdScreenType.PlayListElement,
            name: `Playlist element: ${playlistElementSettings.text.slice(0, 30)}`,
            nodes: savedPlaylistElements.map((playlistElement: AssetDetails): AdsNode => ({
              id: playlistElement.id,
              type: AdNodeType.Asset,
            })),
            id: null,
          })
            .then((screenDetails: ScreenDetails): void => {
              setSavedPlaylistScreens((prev: ScreenDetails[]): ScreenDetails[] => [ ...prev, screenDetails ]);
              const thumbnailsMapClone: Map<string, string> = new Map(savedPlaylistThumbnails); 
              if (coverAsset) {
                thumbnailsMapClone.set(screenDetails.id, coverAsset.value);
              }
              setSavedPlaylistThumbnails(thumbnailsMapClone);
              saveAnimationStateValues({ playlistScreens: [ ...savedPlaylistScreens, screenDetails ] });
              addToast({ content: t('chic.management.usePlaylistAnimations.onCreateNewScreen.success') });
            });
        });
    } else {
      return Promise.resolve();
    }
  };

  const onDeletePlaylistScreen: (items: FileSettingsBox[]) => void = (items: FileSettingsBox[]): void => {
    const sortedSavedScreens: ScreenDetails[] = savedPlaylistScreens.filter((screen: ScreenDetails): boolean => {
      return items.some((item: FileSettingsBox): boolean => {
        if (item.id) {
          return item.id === screen.id;
        } else {
          return item.name === screen.name;
        }
      });
    });
    
    setSavedPlaylistScreens(sortedSavedScreens);
    saveAnimationStateValues({ playlistScreens: sortedSavedScreens });
  };

  const onEditPlaylistScreen: (item: FileSettingsBox) => void = (item: FileSettingsBox): void => {
    const searchedSavedScreen: ScreenDetails | null = savedPlaylistScreens
      .find((savedScreen: ScreenDetails): boolean => item.id
        ? item.id === savedScreen.id
        : item.name === savedScreen.name,
      ) ?? null;

    if (searchedSavedScreen?.id) {
      // TODO: fix when unions will be ready
      const coverNode: ScreenNodeDetails | undefined = searchedSavedScreen.nodes
        .find((node: ScreenDetails | ScreenNodeDetails): boolean => node.type === FileTypeExtended.Image) as ScreenNodeDetails;
      const coverAssetRequest: Promise<void> = getAssetDetails(coverNode.id)
        .then(setCoverAssetDetails)
        .catch((): void => undefined);

      // TODO: fix when unions will be ready
      const videoNode: ScreenNodeDetails | undefined = searchedSavedScreen.nodes
        .find((node: ScreenDetails | ScreenNodeDetails): boolean => node.type === FileTypeExtended.Video) as ScreenNodeDetails;
      const videoAssetRequest: Promise<void> = getAssetDetails(videoNode.id)
        .then(setVideoAssetDetails)
        .catch((): void => undefined);

      Promise.all([videoAssetRequest, coverAssetRequest])
        .then((): void => {
          getScreenDetails(searchedSavedScreen?.id)
            .then(setScreenToEdit)
            .catch((): void => undefined);
        });
    }
  };

  const transformScreenDetailsToAddFileInitials: (screen: ScreenDetails | null) => AddFileInitialData[] | undefined = (
    screen: ScreenDetails | null,
  ): AddFileInitialData[] | undefined => {
    if (!screen) {
      return undefined;
    }

    // TODO: fix when unions will be ready
    const textNode: ScreenNodeDetails | undefined = screen.nodes
      .find((node: ScreenDetails | ScreenNodeDetails): boolean => node.type === FileTypeExtended.Text) as ScreenNodeDetails;


    if (coverAssetDetails && textNode && videoAssetDetails) {
      return [
        {
          path: coverAssetDetails.value,
          name: coverAssetDetails.name,
          format: coverAssetDetails.type,
          size: coverAssetDetails.fileInfo?.size.formatted ?? t('chic.management.global.noData'),
          text: textNode.value,
        },
        {
          path: videoAssetDetails.value,
          name: videoAssetDetails.name,
          format: videoAssetDetails.type,
          size: videoAssetDetails.fileInfo?.size.formatted ?? t('chic.management.global.noData'),
          isVideo: true,
        },
      ];
    }
  };

  const findIndexByItemId: (items: FileSettingsBox[], value: string) => number = (items: FileSettingsBox[], value: string): number => {
    return items.findIndex((item: FileSettingsBox) => item.id === value);
  };

  const onSavedScreensChangePositions: (items: FileSettingsBox[]) => void = (items: FileSettingsBox[]): void => {
    const sortedSavedScreens: ScreenDetails[] = savedPlaylistScreens.sort((a: ScreenDetails, b: ScreenDetails): number => {
      const indexA: number = findIndexByItemId(items, a.id);
      const indexB: number = findIndexByItemId(items, b.id);
      return indexA - indexB;
    });
    
    setSavedPlaylistScreens(sortedSavedScreens);
    saveAnimationStateValues({ playlistScreens: sortedSavedScreens });
  };

  return { 
    onAddPlaylistCover,
    onAddPlaylistFile,
    onSavePlaylistElement,
    onChangePlaylistElementSettings,
    savedPlaylistScreens,
    resetUploadedCover,
    resetUploadedFile,
    uploadCoverError,
    uploadFileError,
    savedPlaylistThumbnails,
    playlistRequirements,
    onAddNewPlaylistItem,
    onDeletePlaylistScreen,
    onEditPlaylistScreen,
    playlistAcceptedFilesTypes,
    onSavedScreensChangePositions,
    transformScreenDetailsToAddFileInitials,
    screenToEdit,
  };
};
