import { GoalsStaticDefinition, UseGoalsContext, UseGoalsStatics } from '@chic/models';
import { useGoalsContext } from './useGoalsContext.hook';
import { GoalType } from '@chic/enums';

export const useGoalsStatics: () => UseGoalsStatics = (): UseGoalsStatics => {
  const { goalsDictionary }: UseGoalsContext = useGoalsContext();

  const getGoalTypeLabel: (type: GoalType) => string = (type: GoalType): string => {
    if (!goalsDictionary) {
      return '';
    }

    return goalsDictionary.goalTypes.find((goalType: GoalsStaticDefinition<GoalType>): boolean => goalType.code === type)?.name ?? '';
  };

  return { getGoalTypeLabel };
};
