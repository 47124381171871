import { BaseViewForSubscriptionDetails } from '@chic/components';
import React from 'react';
import { 
  FileFromViews, 
  SubscriptionDetailsPageName, 
} from '@chic/enums';
import { Params, useParams } from 'react-router-dom';
import { 
  ChildrenContainer,
  ChildrenLabel, 
  HistorySimpleTables, 
  InnerContainer, 
  ProductsWrapper, 
  StatusDot, 
  StyledTransactionProductBox, 
} from './subscriptionDetailsOrdersHistory.styled';
import { 
  Badge, 
  BadgeSize, 
  Breakpoint, 
  Color, 
  DetailsRowTheme, 
  DetailsTable, 
  DetailsTableCellType, 
  DetailsTableMobileTheme, 
  DetailsTableRow, 
  DetailsTableTheme, 
  HistorySimpleTable, 
  HistorySimpleTableCell, 
  HistorySimpleTableHeader, 
  HistorySimpleTableRow, 
  IconName, 
  ResultInfoBox, 
  useParsers, 
  UseParsers,
  VisibilitySettings, 
} from '@chic-loyalty/ui';
import { TransProps, useTranslation } from 'react-i18next';
import { HistoryDeliveriesTableColumnName, HistoryPaymentsTableColumnName, OrdersHistoryTableColumnName } from '../enums';
import { UseSubscriptionDetailsData } from '../types';
import { useSubscriptionDetailsData } from '../hooks';
import { 
  SubscriptionDelivery, 
  SubscriptionOrder, 
  SubscriptionPayment, 
  SubscriptionProduct, 
  UseStatics, 
  UseSubscriptionsUtils, 
} from '@chic/models';
import { useStatics, useSubscriptionsUtils } from '@chic/hooks';
import { 
  orderStatusTheme, 
  paymentStatusTheme, 
  deliveryStatusTheme, 
  mobileOrderStatusColor, 
} from './subscriptionDetailsOrdersHistory.const';
import { useMediaQuery } from 'react-responsive';
import { deliveryPickupPointTypes } from '@chic/constans';

export const SubscriptionDetailsOrdersHistoryView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { parsePrice }: UseParsers = useParsers();
  const { subscriptionId }: Params = useParams();
  const { 
    getDeliveryNameByType, 
    getOrderStatusByType, 
    getPaymentStatusByType, 
    getPaymentNameByType,
    getDeliveryStatusByType,
  }: UseStatics = useStatics();
  const { subscriptionOrders, actionsPermissions }: UseSubscriptionDetailsData = useSubscriptionDetailsData();
  const { transformLocationToDeliveryAddress }: UseSubscriptionsUtils = useSubscriptionsUtils();
  const currency: string = 'zł';
  const isMobile: boolean = useMediaQuery({ query: Breakpoint.Mobile });
  const isSmallMobile: boolean = useMediaQuery({ query: Breakpoint.SmallMobile });

  const swapHeadersInHistoryTable: (headers: HistorySimpleTableHeader[]) => HistorySimpleTableHeader[] = (
    headers: HistorySimpleTableHeader[],
  ): HistorySimpleTableHeader[] => {
    if (isMobile) {
      return headers.reverse();
    } else {
      return headers;
    }
  };

  const swapCellsInHistoryTable: (cells: HistorySimpleTableCell[]) => HistorySimpleTableCell[] = (
    cells: HistorySimpleTableCell[],
  ): HistorySimpleTableCell[] => {
    if (isMobile) {
      return cells.reverse();
    } else {
      return cells;
    }
  };

  return (
    <BaseViewForSubscriptionDetails  
      activeTab={SubscriptionDetailsPageName.OrdersHistory}  
      subscriptionId={subscriptionId}  
      isNewOrderButtonActive={!!actionsPermissions.createOrder}
    >
      <InnerContainer>
        {subscriptionOrders.length ? (
          <DetailsTable 
            headers={[
              { name: OrdersHistoryTableColumnName.MobileStatus }, 
              {
                name: OrdersHistoryTableColumnName.Id,
                label: t('chic.management.subscriptionDetailsOrdersHistory.ordersHistoryTableColumnName.id'),
              }, 
              {
                name: OrdersHistoryTableColumnName.Price,
                label: t('chic.management.subscriptionDetailsOrdersHistory.ordersHistoryTableColumnName.price'),
              }, 
              {
                name: OrdersHistoryTableColumnName.DeliveryDate,
                label: t('chic.management.subscriptionDetailsOrdersHistory.ordersHistoryTableColumnName.deliveryDate'),
              }, 
              {
                name: OrdersHistoryTableColumnName.DeliveryType,
                label: t('chic.management.subscriptionDetailsOrdersHistory.ordersHistoryTableColumnName.deliveryType'),
              }, 
              {
                name: OrdersHistoryTableColumnName.DeliveryNumber,
                label: t('chic.management.subscriptionDetailsOrdersHistory.ordersHistoryTableColumnName.deliveryNumber'),
              }, 
              {
                name: OrdersHistoryTableColumnName.Status,
                label: t('chic.management.subscriptionDetailsOrdersHistory.ordersHistoryTableColumnName.status'),
              },
            ]}
            tableData={subscriptionOrders.map((order: SubscriptionOrder): DetailsTableRow => {
              const lastDelivery: SubscriptionDelivery | undefined = order.deliveries[order.deliveries.length - 1] ?? undefined;

              return {
                theme: DetailsRowTheme.Normal,
                cells: [
                  {
                    name: OrdersHistoryTableColumnName.MobileStatus,
                    cellType: DetailsTableCellType.Children,
                    customAttributes: {
                      children: (
                        <StatusDot $backgroundColor={mobileOrderStatusColor[order.status]} />
                      ),
                    },
                    visibilitySettings: VisibilitySettings.OnlyMobile,
                  },
                  {
                    name: OrdersHistoryTableColumnName.Id,
                    value: isMobile 
                      ? t('chic.management.subscriptionDetailsOrdersHistory.id.mobileValue', { id: order.id }) 
                      : String(order.id),
                    withWordWrap: false,
                  },
                  {
                    name: OrdersHistoryTableColumnName.Price,
                    value: `${parsePrice(order.price)} ${currency}`,
                    iconDetails: {
                      icon: IconName.Money,
                      iconColor: Color.ICYellow100,
                    },
                  },
                  {
                    name: OrdersHistoryTableColumnName.DeliveryDate,
                    value: order?.plannedDeliveryDate ?? t('chic.management.global.noData'),
                    color: order?.plannedDeliveryDate ? Color.ICWhite100 : Color.ICGray400,
                    iconDetails: {
                      icon: IconName.Calendar,
                      iconColor: Color.ICYellow100,
                    },
                  },
                  {
                    name: OrdersHistoryTableColumnName.DeliveryType,
                    value: lastDelivery?.type ? getDeliveryNameByType(lastDelivery.type) : t('chic.management.global.noData'),
                    color: lastDelivery?.type ? Color.ICWhite100 : Color.ICGray400,
                    iconDetails: {
                      iconColor: Color.ICYellow100,
                      icon: lastDelivery?.type 
                        ? deliveryPickupPointTypes.includes(lastDelivery.type) ? IconName.ParcelLocker : IconName.DeliveryCourier
                        : IconName.ErrorInCircle,
                    },
                  },
                  {
                    name: OrdersHistoryTableColumnName.DeliveryNumber,
                    value: lastDelivery?.trackingNumber ?? t('chic.management.global.noData'),
                    color: lastDelivery?.trackingNumber ? Color.ICWhite100 : Color.ICGray400,
                  },
                  {
                    name: OrdersHistoryTableColumnName.Status,
                    cellType: DetailsTableCellType.Children,
                    customAttributes: {
                      children: (
                        <Badge 
                          colorTheme={orderStatusTheme[order.status]}
                          size={BadgeSize.Small} 
                          label={getOrderStatusByType(order.status)} 
                        />
                      ),
                    },
                    visibilitySettings: VisibilitySettings.OnlyDesktop,
                  },
                ],
                children: (
                  <ChildrenContainer>
                    <ProductsWrapper>
                      <ChildrenLabel>
                        {t('chic.management.subscriptionDetailsOrdersHistory.childrenProducts.label')}
                      </ChildrenLabel>
                      {order.products.map((product: SubscriptionProduct): JSX.Element => (
                        <StyledTransactionProductBox 
                          name={product.productName}
                          category={product.categoryName}
                          ean={product.ean}
                          amount={product.amount}
                          image={product.image ?? undefined}
                          price={product.unitPrice}
                          key={product.productId}
                        />
                      ))}
                    </ProductsWrapper>
                    <HistorySimpleTables>
                      {!!order.payments.length && (
                        <>
                          {!!isMobile && (
                            <ChildrenLabel>
                              {t('chic.management.subscriptionDetailsOrdersHistory.historyTable.payments.title')}
                            </ChildrenLabel>
                          )}
                          <HistorySimpleTable 
                            title={t('chic.management.subscriptionDetailsOrdersHistory.historyTable.payments.title')} 
                            headers={[
                              {
                                name: HistoryPaymentsTableColumnName.Id,
                                label: t('chic.management.subscriptionDetailsOrdersHistory.historyTable.payments.id'),
                              },
                              ...(swapHeadersInHistoryTable([
                                {
                                  name: HistoryPaymentsTableColumnName.Type,
                                  label: t('chic.management.subscriptionDetailsOrdersHistory.historyTable.payments.type'),
                                },
                                {
                                  name: HistoryPaymentsTableColumnName.Status,
                                  label: t('chic.management.subscriptionDetailsOrdersHistory.historyTable.payments.status'),
                                },
                              ])),
                              {
                                name: HistoryPaymentsTableColumnName.Price,
                                label: t('chic.management.subscriptionDetailsOrdersHistory.historyTable.payments.price'),
                              },
                            ]}
                            rows={order.payments.map((payment: SubscriptionPayment): HistorySimpleTableRow => ({
                              cells: [
                                {
                                  name: HistoryPaymentsTableColumnName.Id,
                                  label: String(payment.id),
                                },
                                ...(swapCellsInHistoryTable([
                                  {
                                    name: HistoryPaymentsTableColumnName.Type,
                                    label: getPaymentNameByType(payment.type),
                                  },
                                  {
                                    name: HistoryPaymentsTableColumnName.Status,
                                    label: getPaymentStatusByType(payment.status),
                                    badgeColor: paymentStatusTheme[payment.status],
                                  },
                                ])),
                                {
                                  name: HistoryPaymentsTableColumnName.Price,
                                  label: `${parsePrice(payment.amount)} ${currency}`,
                                  isHighlighted: true,
                                },
                              ],
                            }))} 
                            background={FileFromViews.SubscriptionDetailsOrdersHistoryViewPaymentsTableBackground}
                            additionalImage={FileFromViews.SubscriptionDetailsOrdersHistoryViewDevices} 
                          />
                        </>
                      )}
                      {!!order.deliveries.length && (
                        <>
                          {!!isMobile && (
                            <ChildrenLabel>
                              {t('chic.management.subscriptionDetailsOrdersHistory.historyTable.deliveries.title')}
                            </ChildrenLabel>
                          )}
                          <HistorySimpleTable 
                            title={t('chic.management.subscriptionDetailsOrdersHistory.historyTable.deliveries.title')} 
                            headers={[
                              {
                                name: HistoryDeliveriesTableColumnName.Type,
                                label: t('chic.management.subscriptionDetailsOrdersHistory.historyTable.deliveries.type'),
                              },
                              {
                                name: HistoryDeliveriesTableColumnName.Status,
                                label: t('chic.management.subscriptionDetailsOrdersHistory.historyTable.deliveries.status'),
                              },
                              {
                                name: HistoryDeliveriesTableColumnName.Price,
                                label: t('chic.management.subscriptionDetailsOrdersHistory.historyTable.deliveries.price'),
                              },
                              {
                                name: HistoryDeliveriesTableColumnName.Address,
                                label: t('chic.management.subscriptionDetailsOrdersHistory.historyTable.deliveries.address'),
                              },
                            ]}
                            rows={order.deliveries.map((delivery: SubscriptionDelivery): HistorySimpleTableRow => ({
                              cells: [
                                {
                                  name: HistoryDeliveriesTableColumnName.Type,
                                  label: getDeliveryNameByType(delivery.type),
                                },
                                {
                                  name: HistoryDeliveriesTableColumnName.Status,
                                  label: isSmallMobile 
                                    ? getDeliveryStatusByType(delivery.status)
                                      .slice(0, 11)
                                      .concat('...')
                                    : getDeliveryStatusByType(delivery.status),
                                  badgeColor: deliveryStatusTheme[delivery.status],
                                },
                                {
                                  name: HistoryDeliveriesTableColumnName.Price,
                                  label: `${parsePrice(delivery.cost)} ${currency}`,
                                },
                                {
                                  name: HistoryDeliveriesTableColumnName.Address,
                                  label: transformLocationToDeliveryAddress(delivery.location),
                                },
                              ],
                            }))}   
                            background={FileFromViews.SubscriptionDetailsOrdersHistoryViewDeliveriesTableBackground} 
                          />
                        </>
                      )}
                    </HistorySimpleTables>
                  </ChildrenContainer>
                ),
              };
            })} 
            tableTheme={DetailsTableTheme.Dropdown}
            mobileTheme={DetailsTableMobileTheme.Dropdown}
            columnsVisibleOnMobile={[OrdersHistoryTableColumnName.MobileStatus, OrdersHistoryTableColumnName.Id]}
          />
        ) : (
          <ResultInfoBox 
            text={t('chic.management.subscriptionDetailsOrdersHistory.emptyState')}
            backgroundImage={FileFromViews.SubscriptionDetailsAmendmentsHistoryViewEmptyState}
          />
        )}
      </InnerContainer>
    </BaseViewForSubscriptionDetails>
  );
};
