import { CalcSize, Color, Breakpoint, hexToRgba, StyledComponent } from '@chic-loyalty/ui';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import { ContainerProps } from './baseViewForGoals.types';

export const Container: StyledComponent<'div', ContainerProps> = styled.div<ContainerProps>`
  width: 100%;
  min-height: calc(100vh + ${CalcSize.FooterHeight});
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({ $background }: ContainerProps): FlattenSimpleInterpolation => $background ? css`
    background-image: url(${$background});
    background-size: 100% auto;
    background-repeat: no-repeat;
  ` : css``}
  background-color: ${Color.ICBlack500};
  
  @media ${Breakpoint.SmallMobile} {
    ${({ $backgroundMobile }: ContainerProps): FlattenSimpleInterpolation => $backgroundMobile ? css`
      background-image: url('${$backgroundMobile}');
  ` : css``}
    border-top: 1px solid ${hexToRgba(Color.ICWhite100, 0.2)};
  }
`;

export const PageTitleWrapper: StyledComponent<'div'> = styled.div`
  width: 100%;
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  
  @media ${Breakpoint.Mobile} {
    padding: 24px 20px;
    border-bottom: 1px solid ${hexToRgba(Color.ICWhite100, 0.2)};
    flex-direction: column;
    align-items: flex-start;
  }
`;
