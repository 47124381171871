import { Breakpoint, CalcSize, Color, Grid, Pagination, StyledComponent, TabSwitch } from '@chic-loyalty/ui';
import { FileFromViews } from '@chic/enums';
import styled from 'styled-components';

export const Container: StyledComponent<'div'> = styled.div`
  width: 100%;
  min-height: calc(100vh - ${CalcSize.FooterHeight});
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url('${FileFromViews.AdsListViewBackground}');
  background-color: ${Color.ICBlack500};
  background-size: cover;
  background-repeat: no-repeat;
`;

export const PageTitleWrapper: StyledComponent<'div'> = styled.div`
  max-width: calc(${Grid.ICSmallDesktop} + 48px);
  width: 100%;
  padding: 24px 24px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const PageTitleButtons: StyledComponent<'div'> = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

export const Spacer: StyledComponent<'div'> = styled.div`
  width: 1px;
  height: 16px;
  background-color: ${Color.ICWhite100};
`;

export const InnerContainer: StyledComponent<'div'> = styled.div`
  width: 100%;
  flex: 1;
  padding: 62px 24px;
  max-width: calc(${Grid.ICSmallDesktop} + 48px);
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const StyledTabSwitch: StyledComponent<typeof TabSwitch> = styled(TabSwitch)`
  width: 514px;

  @media ${Breakpoint.Mobile} {
    width: 100%;
  }
`;

export const StyledPagination: StyledComponent<typeof Pagination> = styled(Pagination)`
  margin: 48px 0;
`;

export const ButtonsWrapper: StyledComponent<'div'> = styled.div`
  padding: 40px 32px;
  background: linear-gradient(85.01deg, ${Color.ICBlack100} 0.65%, ${Color.ICBlack500} 110.02%);
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
`;

export const LoadingWrapper: StyledComponent<'div'> = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  flex: 1;
`;
