import React, { useState } from 'react';
import { remindPassword } from '@chic/api';
import { TransProps, useTranslation } from 'react-i18next';
import {
  Container,
  InnerContainer,
  StyledButton,
  PageTitleWrapper,
  Description,
  StyledInput,
  StyledValidationBar,
} from './remindPassword.styled';
import { 
  ButtonTheme, 
  ComponentColorTheme, 
  FullscreenAlertTheme, 
  PageTitle, 
  PageTitleSize, 
  UseNotifications, 
  UseState, 
  ValidationBarTheme, 
  useNotifications, 
} from '@chic-loyalty/ui';

export const RemindPasswordView: React.FC = (): JSX.Element => {
  const [login, setLogin]: UseState<string> = useState<string>('');
  const [message, setMessage]: UseState<string | null> = useState<string | null>(null);
  const [isButtonLoading, setIsButtonLoading]: UseState<boolean> = useState<boolean>(false);
  const { t }: TransProps<never> = useTranslation();
  const { showFullscreenAlert, hideFullscreenAlert }: UseNotifications = useNotifications();

  const handleRemindPassword: () => void = (): void => {
    setIsButtonLoading(true);
    remindPassword(login)
      .then((): void => setMessage(t('chic.crmApp.remindPasswordView.remindPassword.success')))
      .catch((): void => {
        showFullscreenAlert({
          title: t('chic.crmApp.remindPasswordView.remindPassword.error'),
          fullscreenAlertTheme: FullscreenAlertTheme.Error,
          acceptButtonSettings: { 
            label: t('chic.crmApp.global.close'),
            action: hideFullscreenAlert, 
          },
        });
      })
      .finally((): void => setIsButtonLoading(false));
  };

  return (
    <Container>
      <PageTitleWrapper>
        <PageTitle size={PageTitleSize.Big} label={t('chic.crmApp.remindPasswordView.pageTitle')} />
      </PageTitleWrapper>
      <InnerContainer>
        <Description text={t('chic.crmApp.remindPasswordView.description')} />
        <StyledInput  
          onChange={setLogin}
          label={t('chic.crmApp.remindPasswordView.input.label')}
          value={login}
          placeholder={t('chic.crmApp.remindPasswordView.input.placeholder')}
          required
        />
        <StyledButton
          onClick={handleRemindPassword}
          disabled={!login.length}
          label={t('chic.crmApp.remindPasswordView.button.label')}
          buttonTheme={ButtonTheme.Primary}
          isLoading={isButtonLoading}
        />
        {!!message && <StyledValidationBar message={message} barTheme={ValidationBarTheme.Gray} colorTheme={ComponentColorTheme.IC} />}
      </InnerContainer>
    </Container>
  );
};
