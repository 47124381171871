import React, { useMemo, useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { DiscountCodeDetailsProps } from './discountCodeDetails.types';
import { UseQueryResult, useQuery } from 'react-query';
import { QueryKey } from '@chic/enums';
import { codeStatuses } from './discountCodeDetails.constants';
import {
  AccordionHeader, 
  AccordionHeaderText, 
  AccordionSection,
  AccordionTransactions,
  AccordionUseDetailsBoxes,
  AccordionWrapper,
  Container,
  ContentBox,
  ContentWrapper, 
  CodeInfo, 
  CodeDetailsBox,
  UseDetails,
  UseDetailsBoxes,
  Transactions,
  TransactionsWrapper,
  TransactionsItem,
  CodeInfoImageContainer,
  CodeInfoImageWrapper,
  CodeInfoRightSection,
  CodeInfoSubHeader,
  CodeInfoHeader,
  CodeInfoRow,
  CodeInfoText,
} from './discountCodeDetails.styled';
import { 
  AccordionExtended, 
  ComponentColorTheme, 
  DetailsInfoGroup, 
  DetailsInfoItem, 
  IconName, 
  PageTitle, 
  PageTitleSize, 
  Pagination, 
  PaginationDetails, 
  SimpleInfoBox, 
  Status, 
  TransactionProductBox,
  UseState,
  usePagination, 
} from '@chic-loyalty/ui';
import { getCodeDetails, getCodeTransactions } from '@chic/api';
import { DiscountCodeDetails, DiscountCodeTransaction, ListPagination, TransactionCartElement } from '@chic/models';
import CodeInfoImage from './images/codeInfoImage.jpg';

export const DiscountCodeDetailsComponent: React.FC<DiscountCodeDetailsProps> = (props: DiscountCodeDetailsProps): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { discountCode, className } = props;
  const [codeDetails, setCodeDetails]: UseState<DiscountCodeDetails | null> = useState<DiscountCodeDetails | null>(null);
  const [codeTransactionsList, setCodeTransactionsList]: UseState<DiscountCodeTransaction[]> = useState<DiscountCodeTransaction[]>([]);
  const { setMaxItems, setPage, itemsPerPage, offset, page, pagesCount }: PaginationDetails = usePagination();
  
  useQuery(
    [QueryKey.CodeDetails, discountCode],
    (): Promise<DiscountCodeDetails> => getCodeDetails(discountCode),
    {
      onSuccess: (data: DiscountCodeDetails): void => setCodeDetails(data),
      onError: (): void => undefined,
    },
  );

  const { isFetching }: UseQueryResult = useQuery(
    [QueryKey.CodeTransactions, discountCode, offset],
    (): Promise<ListPagination<DiscountCodeTransaction>> => getCodeTransactions(discountCode, { offset, limit: itemsPerPage }),
    {
      onSuccess: (data: ListPagination<DiscountCodeTransaction>): void => {
        setCodeTransactionsList(data.list);
        setMaxItems(data.amount);
      },
      onError: (): void => undefined,
    },
  );
  

  const detailsInfoList: DetailsInfoItem[] = useMemo((): DetailsInfoItem[] => [
    {
      label: t('chic.management.discountCodeDetailsComponent.detailsInfoList.expirationDate.label'),
      value: codeDetails?.expirationDate ?? t('chic.management.global.noItem'),
    },
    {
      label: t('chic.management.discountCodeDetailsComponent.detailsInfoList.requiredLogin.label'),
      value: codeDetails?.requireLogin 
        ? t('chic.management.global.yes') 
        : t('chic.management.global.no'),
    },
    {
      label: t('chic.management.discountCodeDetailsComponent.detailsInfoList.code.label'),
      value: codeDetails?.promotion.code ?? t('chic.management.global.noItem'),
    },
    {
      label: t('chic.management.discountCodeDetailsComponent.detailsInfoList.name.label'),
      value: codeDetails?.promotion.name ?? t('chic.management.global.noItem'),
    },
    {
      label: t('chic.management.discountCodeDetailsComponent.detailsInfoList.description.label'),
      value: codeDetails?.promotion.description ?? t('chic.management.global.noItem'),
    },
  ], [codeDetails]);
  
  return (
    <Container className={className}>
      {!!codeDetails && (
        <>
          <CodeInfo>
            <CodeInfoImageContainer>
              <CodeInfoImageWrapper src={CodeInfoImage} />
            </CodeInfoImageContainer>
            <CodeInfoRightSection>
              <CodeInfoSubHeader>{t('chic.management.discountCodeDetailsComponent.codeInfoDetails.label')}</CodeInfoSubHeader>
              <CodeInfoHeader>{codeDetails.code}</CodeInfoHeader>
              <CodeInfoRow>
                <CodeInfoText>{t('chic.management.discountCodeDetailsComponent.codeInfoDetails.status')}</CodeInfoText>
                <Status
                  label={t(codeStatuses[codeDetails.status].label)}
                  color={codeStatuses[codeDetails.status].color}
                  colorTheme={ComponentColorTheme.IC}
                />
              </CodeInfoRow>
            </CodeInfoRightSection>
          </CodeInfo>
          <ContentWrapper>
            <ContentBox>
              <CodeDetailsBox>
                <PageTitle label={t('chic.management.discountCodeDetailsComponent.codeDetailsBox.label')} />
                <DetailsInfoGroup items={detailsInfoList} />
              </CodeDetailsBox>
              {!isFetching && codeTransactionsList.length > 0 && codeDetails.multiple && (
                <AccordionSection>
                  <PageTitle label={t('chic.management.discountCodeDetailsComponent.useDetails.label')} />
                  <AccordionWrapper>
                    <AccordionHeader>
                      <AccordionHeaderText>
                        {t('chic.management.discountCodeDetailsComponent.accordion.header.first')}
                      </AccordionHeaderText>
                      <AccordionHeaderText>
                        {t('chic.management.discountCodeDetailsComponent.accordion.header.second')}
                      </AccordionHeaderText>
                    </AccordionHeader>
                    {codeTransactionsList.map((transaction: DiscountCodeTransaction, index: number): JSX.Element => (
                      <AccordionExtended 
                        headerCells={[
                          {
                            icon: IconName.Date,
                            label: transaction.datetime,
                          },
                          {
                            icon: IconName.SmallPhotos,
                            label: transaction.docNumber,
                          },
                        ]} 
                        key={index}
                        colorTheme={ComponentColorTheme.IC}
                      >
                        <UseDetails>
                          <AccordionUseDetailsBoxes>
                            <SimpleInfoBox
                              title={t('chic.management.discountCodeDetailsComponent.simpleInfoBox.datetime.title')}
                              descriptions={[transaction.datetime]}
                            />
                            {transaction.customer && (
                              <SimpleInfoBox
                                title={t('chic.management.discountCodeDetailsComponent.simpleInfoBox.customer.title')}
                                descriptions={[`eSC ID: ${transaction.customer.id ?? 'Brak eSC ID'}
                                  ${transaction.customer.email ?? ''}
                                  ${transaction.customer.phone ?? ''}
                                `]}
                              />
                            )}
                          </AccordionUseDetailsBoxes>
                        </UseDetails>
                        <AccordionTransactions>
                          <PageTitle 
                            label={t('chic.management.discountCodeDetailsComponent.transactions.label')} 
                            size={PageTitleSize.Small}
                          />
                          <TransactionsWrapper>
                            {transaction.cart.map((item: TransactionCartElement, cartIndex: number): JSX.Element => (
                              <TransactionsItem key={cartIndex}>
                                <TransactionProductBox
                                  name={item.product.name}
                                  price={item.unitPrice}
                                  ean={item.product.ean}
                                  amount={item.amount}
                                  image={item.product.photo ?? undefined}
                                />
                              </TransactionsItem>
                            ))}
                          </TransactionsWrapper>
                        </AccordionTransactions>
                      </AccordionExtended>
                    ))}
                  </AccordionWrapper>
                  {pagesCount > 1 && (
                    <Pagination
                      activePage={page}
                      pagesCount={pagesCount}
                      onActivePageChange={setPage}
                      colorTheme={ComponentColorTheme.IC}
                    />
                  )}
                </AccordionSection>
              )}
              {!!codeTransactionsList[0] && !codeDetails.multiple && (
                <UseDetails>
                  <PageTitle label={t('chic.management.discountCodeDetailsComponent.useDetails.label')} />
                  <UseDetailsBoxes>
                    <SimpleInfoBox
                      title={t('chic.management.discountCodeDetailsComponent.simpleInfoBox.datetime.title')}
                      descriptions={[codeTransactionsList[0].datetime]}
                    />
                    <SimpleInfoBox
                      title={t('chic.management.discountCodeDetailsComponent.simpleInfoBox.docNumber.title')}
                      descriptions={[codeTransactionsList[0].docNumber]}
                    />
                    {codeTransactionsList[0].store && (
                      <SimpleInfoBox
                        title={t('chic.management.discountCodeDetailsComponent.simpleInfoBox.store.title')}
                        descriptions={[
                          `${codeTransactionsList[0].store.id} ${codeTransactionsList[0].store.name} 
                          ${codeTransactionsList[0].store.address}, ${codeTransactionsList[0].store.postalCode}`
                          + ` ${codeTransactionsList[0].store.city}`,
                        ]}
                      />
                    )}
                    {codeTransactionsList[0].customer && (
                      <SimpleInfoBox
                        title={t('chic.management.discountCodeDetailsComponent.simpleInfoBox.customer.title')}
                        descriptions={[`eSC ID: ${codeTransactionsList[0].customer.id ?? 'Brak eSC ID'}
                          ${codeTransactionsList[0].customer.email ?? ''}
                          ${codeTransactionsList[0].customer.phone ?? ''}
                        `]}
                      />
                    )}
                  </UseDetailsBoxes>
                </UseDetails>
              )}
            </ContentBox>
            {!!codeTransactionsList[0] && !codeDetails.multiple && (
              <ContentBox>
                <Transactions>
                  <PageTitle label={t('chic.management.discountCodeDetailsComponent.transactions.label')} />
                  <TransactionsWrapper>
                    {codeTransactionsList[0].cart.map((item: TransactionCartElement, index: number): JSX.Element => (
                      <TransactionsItem key={index}>
                        <TransactionProductBox
                          category={item.categoryName}
                          name={item.product.name}
                          price={item.unitPrice}
                          ean={item.product.ean}
                          amount={item.amount}
                          image={item.product.photo ?? undefined}
                        />
                      </TransactionsItem>
                    ))}
                  </TransactionsWrapper>
                </Transactions>
              </ContentBox>
            )}
          </ContentWrapper>
        </>
      )}
    </Container>
  );
};
