import { 
  Breakpoint, 
  Button, 
  ButtonTheme, 
  ChoiceBoxBanner, 
  Input, 
  InputTheme, 
  OptionsSwitch, 
  OptionsSwitchTheme, 
  PageTitle, 
  PageTitleSize, 
  UseFormikForm, 
  useFormikForm,
} from '@chic-loyalty/ui';
import * as Yup from 'yup';
import { useTitle } from '@chic/hooks';
import React from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { 
  Container, 
  PageTitleWrapper, 
  InnerContainer, 
  ButtonsBox, 
  FormikForm, 
  Section, 
  InputsRow, 
  PostalCodeInput, 
} from './subscriptionEditDelivery.styled';
import { UpdateSubscriptionDeliveryBasicData, UseSubscriptionEditDelivery } from './subscriptionEditDelivery.types';
import { Formik, FormikProps } from 'formik';
import { useMediaQuery } from 'react-responsive';
import { SubscriptionDeliveryField } from './subscriptionEditDelivery.enums';
import { pickupPointsDeliveryTypeCodes } from '@chic/constans';
import { useSubscriptionEditDelivery, useSubscriptionOrderEditDeliveryValidations } from './hooks';

export const SubscriptionEditDeliveryView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { 
    goBack, 
    mappedDeliveriesToOptionSwitch, 
    selectedDelivery,
    onChangeSelectedDelivery,
    initialFormValues,
    choiceBoxBannerFilledData,
    onFormSubmit,
    pickupPointData,
  }: UseSubscriptionEditDelivery = useSubscriptionEditDelivery();
  const { setFormSubmitted, isFormSubmitted }: UseFormikForm = useFormikForm();
  const subscriptionDeliverySchema: Yup.Schema<UpdateSubscriptionDeliveryBasicData> = useSubscriptionOrderEditDeliveryValidations();
  const isMobile: boolean = useMediaQuery({ query: Breakpoint.Mobile });
  useTitle(t('chic.management.subscriptionEditDeliveryView.title'));

  return (
    <Container>
      <PageTitleWrapper>
        <PageTitle 
          size={PageTitleSize.Big} 
          label={t('chic.management.subscriptionEditDeliveryView.pageTitle')} 
          onClick={goBack}
        />
      </PageTitleWrapper>
      <Formik
        initialValues={initialFormValues}
        onSubmit={onFormSubmit}
        validationSchema={subscriptionDeliverySchema}
        validateOnChange={isFormSubmitted}
        validateOnBlur={isFormSubmitted}
        enableReinitialize
      >
        {({ handleSubmit, setFieldValue, errors, values }: FormikProps<UpdateSubscriptionDeliveryBasicData>): JSX.Element => (
          <FormikForm onSubmit={handleSubmit}>
            <InnerContainer>
              <Section>
                <PageTitle 
                  label={t('chic.management.subscriptionEditDeliveryView.address.title')} 
                  size={PageTitleSize.Small} 
                />
                <Input 
                  label={t('chic.management.global.fullName')}
                  type='text'
                  onChange={(value: string): void => setFieldValue(SubscriptionDeliveryField.Name, value)}
                  value={values[SubscriptionDeliveryField.Name]}
                  inputTheme={errors[SubscriptionDeliveryField.Name] ? InputTheme.Error : InputTheme.Standard}
                  message={errors[SubscriptionDeliveryField.Name]}
                  required
                />
                <Input 
                  label={t('chic.management.global.address')}
                  type='text'
                  onChange={(value: string): void => setFieldValue(SubscriptionDeliveryField.Address, value)}
                  value={values[SubscriptionDeliveryField.Address]}
                  inputTheme={errors[SubscriptionDeliveryField.Address] ? InputTheme.Error : InputTheme.Standard}
                  message={errors[SubscriptionDeliveryField.Address]}
                  required
                />
                <InputsRow>
                  <PostalCodeInput 
                    label={t('chic.management.global.postalCode')}
                    type='text'
                    onChange={(value: string): void => setFieldValue(SubscriptionDeliveryField.PostalCode, value)}
                    value={values[SubscriptionDeliveryField.PostalCode]}
                    inputTheme={errors[SubscriptionDeliveryField.PostalCode] ? InputTheme.Error : InputTheme.Standard}
                    message={errors[SubscriptionDeliveryField.PostalCode]}
                    numericMask='##-###'
                    required
                  />
                  <Input 
                    label={t('chic.management.global.city')}
                    type='text'
                    onChange={(value: string): void => setFieldValue(SubscriptionDeliveryField.City, value)}
                    value={values[SubscriptionDeliveryField.City]}
                    inputTheme={errors[SubscriptionDeliveryField.City] ? InputTheme.Error : InputTheme.Standard}
                    message={errors[SubscriptionDeliveryField.City]}
                    required
                  />
                </InputsRow>
              </Section>
              <Section>
                <PageTitle label={t('chic.management.subscriptionEditDeliveryView.deliveryType.title')} size={PageTitleSize.Small} />
                <OptionsSwitch
                  options={mappedDeliveriesToOptionSwitch}
                  onOptionChange={onChangeSelectedDelivery}
                  activeOptionName={selectedDelivery ?? undefined}
                  theme={OptionsSwitchTheme.Delivery}
                />
                {!!choiceBoxBannerFilledData && (
                  <ChoiceBoxBanner data={choiceBoxBannerFilledData} />
                )}
              </Section>
              <Section>
                <PageTitle label={t('chic.management.subscriptionEditDeliveryView.contact.title')} size={PageTitleSize.Small} />
                <Input 
                  label={t('chic.management.global.phone')}
                  type='text'
                  onChange={(value: string): void => setFieldValue(SubscriptionDeliveryField.Phone, value)}
                  value={values[SubscriptionDeliveryField.Phone]}
                  inputTheme={errors[SubscriptionDeliveryField.Phone] ? InputTheme.Error : InputTheme.Standard}
                  message={errors[SubscriptionDeliveryField.Phone]}
                  hasPhonePrefix
                  disabled
                  required
                />
                <Input 
                  label={t('chic.management.global.email')}
                  type='text'
                  onChange={(value: string): void => setFieldValue(SubscriptionDeliveryField.Email, value)}
                  value={values[SubscriptionDeliveryField.Email]}
                  inputTheme={errors[SubscriptionDeliveryField.Email] ? InputTheme.Error : InputTheme.Standard}
                  message={errors[SubscriptionDeliveryField.Email]}
                  disabled
                  required
                />
              </Section>
            </InnerContainer>
            <ButtonsBox>
              <Button 
                label={t('chic.management.global.cancel')} 
                buttonTheme={ButtonTheme.Secondary} 
                onClick={goBack}
                reverted={!isMobile}
                fullWidth={isMobile}
              />
              <Button 
                type='submit'
                onClick={setFormSubmitted}
                label={t('chic.management.global.save')}
                buttonTheme={ButtonTheme.Primary} 
                disabled={!selectedDelivery || (pickupPointsDeliveryTypeCodes.includes(selectedDelivery) && !pickupPointData)}
                fullWidth={isMobile}
              />
            </ButtonsBox>
          </FormikForm>
        )}
      </Formik>
    </Container>
  );
};
