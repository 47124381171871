import React, { useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import { 
  Container, 
  LogoBox,
  StyledPageTitle, 
  StyledLogo, 
  StyledButton, 
  LoginBox, 
  InnerBlackBox, 
  Title, 
  AlternativeLoginWrapper,
  HintText,
  AlternativeLoginButtonWrapper,
} from './crmSignIn.styled';
import { RoutingPath } from '@chic/enums';
import { useTitle } from '@chic/hooks';
import { useMediaQuery } from 'react-responsive';
import { Breakpoint, Button, ButtonTheme, LogoTheme, UseRedirect, UseState, useRedirect } from '@chic-loyalty/ui';
import { SignInForm } from '@chic/components';

export const CrmSignInView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { redirect }: UseRedirect = useRedirect();
  const [isAlternativeLogin, setIsAlternativeLogin]: UseState<boolean> = useState<boolean>(false);
  useTitle(t('chic.crmApp.crmSignIn.title'));
  const isMobile: boolean = useMediaQuery({ query: Breakpoint.Mobile });

  return (
    <Container>
      <LogoBox>
        {!isMobile && <StyledLogo logoTheme={LogoTheme.ICWhite} />}
      </LogoBox>
      <LoginBox>
        {!isAlternativeLogin ? (
          <InnerBlackBox>
            <StyledPageTitle label={t('chic.crmApp.global.signIn.title')} />
            <Title text={t('chic.crmApp.global.title')} />
            <StyledButton
              label={t('chic.crmApp.crmSignIn.button.label')}
              buttonTheme={ButtonTheme.Primary}
              onClick={(): void => redirect(RoutingPath.CrmSignInRedirect)}
            />
            <AlternativeLoginWrapper>
              <HintText>{t('chic.crmApp.crmSignIn.noCrmAccount')}</HintText>
              <AlternativeLoginButtonWrapper>
                <Button
                  label={t('chic.crmApp.crmSignIn.alternativeLoginButton.label')}
                  buttonTheme={ButtonTheme.Text}
                  onClick={(): void => setIsAlternativeLogin(true)}
                />
              </AlternativeLoginButtonWrapper>
            </AlternativeLoginWrapper>
          </InnerBlackBox>
        ) : (
          <InnerBlackBox>
            <StyledPageTitle label={t('chic.crmApp.global.signIn.title')} onClick={() => setIsAlternativeLogin(false)}/>
            <Title text={t('chic.crmApp.global.title')} />
            <SignInForm />
          </InnerBlackBox>
        )}
      </LoginBox>
    </Container>
  );
};
