import React from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { RoleVerificationBoxProps } from './roleVerificationBox.types';
import { Button, ButtonTheme, ComponentColorTheme, DropdownOption, SmallDropdown } from '@chic-loyalty/ui';
import { Role, UseRoleVerificationBox } from '@chic/models';
import { Container } from './roleVerificationBox.styled';
import { useRoleVerificationBox } from './roleVerificationBox.hooks';
import { QueryClient, useQueryClient } from 'react-query';
import { QueryKey } from '@chic/enums';

export const RoleVerificationBox: React.FC<RoleVerificationBoxProps> = (props: RoleVerificationBoxProps): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { userName, userId, className }: RoleVerificationBoxProps = props;
  const { roles, selectedRole, setSelectedRole, acceptButtonAction }: UseRoleVerificationBox = useRoleVerificationBox();
  const queryClient: QueryClient = useQueryClient();

  return (
    <Container className={className}>
      {!!acceptButtonAction && (
        <>
          <SmallDropdown 
            options={roles?.map((role: Role): DropdownOption => ({
              name: role.name,
              label: role.label,
            })) ?? []}
            onChooseAnswer={setSelectedRole}
            initialValue={selectedRole ?? undefined}
            colorTheme={ComponentColorTheme.IC}
            placeholder={t('chic.crmApp.roleVerificationBox.smallDropdown.placeholder')}
            disabled={!roles?.length}
          />
          <Button 
            label={t('chic.crmApp.roleVerificationBox.button.accept')}
            buttonTheme={ButtonTheme.Text} 
            onClick={(): void => acceptButtonAction(
              userName,
              userId, 
              roles?.find((role: Role): boolean => role.name === selectedRole?.name),
              (): void => void queryClient.invalidateQueries(QueryKey.UsersVerificationList),
            )}
            disabled={!roles?.length || !selectedRole} 
          />
        </>
      )}
    </Container>
  );
};
