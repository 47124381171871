import { RoutingPath } from '@chic/enums';
import { useAuth, useConfig, useRoutingPermissions } from '@chic/hooks';
import { ConfigsContextType, UseAuth, UseRoutingPermissions } from '@chic/models';
import React from 'react';
import { Navigate, useLocation, Location, useMatch, matchPath } from 'react-router-dom';
import { AccessDeniedView } from '../../views';

import { CheckAuthProps } from './checkAuth.types';

export const CheckAuth: React.FC<CheckAuthProps> = (props: CheckAuthProps): JSX.Element => {
  const { children }: CheckAuthProps = props;
  const location: Location = useLocation();
  const { checkRoutePermission }: UseRoutingPermissions = useRoutingPermissions();
  const config: ConfigsContextType = useConfig();
  const { authData }: UseAuth = useAuth();
  const crmSignInPath: string = RoutingPath.CrmSignIn;
  const signInPaths: string[] = [
    RoutingPath.CrmSignIn,
    RoutingPath.CrmSignInRedirect,
    RoutingPath.ReturnFromCrm,
    RoutingPath.SignIn,
    RoutingPath.RemindPassword,
    RoutingPath.ChangePassword,
  ];
  const currentPathKeys: RoutingPath[] = Object.entries(RoutingPath)
    .filter(([routeKey, routeValue]: [string, RoutingPath]): boolean => !!useMatch(routeValue) && routeKey !== RoutingPath.NotFound)
    .map(([, routeValue]: [string, RoutingPath]): RoutingPath => routeValue);
  const currentPathKey: RoutingPath = currentPathKeys.length
    ? currentPathKeys[0]
    : RoutingPath.NotFound;
  const defaultRoute: RoutingPath = !authData.user?.verified
    ? RoutingPath.Dashboard
    : config.defaultRoute;

  if (location.pathname === RoutingPath.Home) {
    return (
      <Navigate to={defaultRoute} replace />
    );
  }

  if (!authData.token && !signInPaths.includes(currentPathKey)) {
    return (
      <Navigate to={crmSignInPath} state={{ from: location }} replace />
    );
  }

  if (config.disabledViews.find((route: RoutingPath): boolean => (
    !!matchPath({ path: route, end: false }, location.pathname)
  )) && currentPathKey !== defaultRoute) {
    return (
      <Navigate to={RoutingPath.NotFound} replace />
    );
  }

  if (authData.token) {
    if (signInPaths.includes(currentPathKey)) {
      return (
        <Navigate to={defaultRoute} replace />
      );
    } else if (checkRoutePermission(currentPathKey)) {
      return (
        <>{children}</>
      );
    } else {
      return (
        <AccessDeniedView />
      );
    }
  }

  return (
    <>{children}</>
  );
};
