import { useSubscriptionDetailsContext, useTitle } from '@chic/hooks';
import { TransProps, useTranslation } from 'react-i18next';
import { SubscriptionPlanProductData, UseSubscriptionDetailsContext } from '@chic/models';
import React, { useEffect, useMemo } from 'react';
import { 
  ButtonsBox,
  Container, 
  InnerContainer, 
  PageTitleWrapper, 
  ProductsBox, 
  StyledTransactionProductBox, 
} from './subscriptionEditProductsSummary.styled';
import { 
  Breakpoint, 
  Button, 
  ButtonTheme, 
  OrderSummaryBox, 
  PageTitle, 
  PageTitleSize, 
  useParsers, 
  UseParsers,
  useRedirect,
  UseRedirect, 
} from '@chic-loyalty/ui';
import { RoutingPath } from '@chic/enums';
import { useMediaQuery } from 'react-responsive';
import {  UseSubscriptionDetailsActionsPermissions } from '../types';
import { useSubscriptionDetailsActionsPermissions } from '../hooks';
import { UseSubscriptionEditProductsSummary } from './subscriptionEditProductsSummary.types';
import { useSubscriptionEditProductsSummary } from './subscriptionEditProductsSummary.hooks';

export const SubscriptionEditProductsSummaryView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { redirect }: UseRedirect = useRedirect();
  const { parsePrice }: UseParsers = useParsers();
  const { userEditProductsData, subscriptionDetails }: UseSubscriptionDetailsContext = useSubscriptionDetailsContext();
  const { actionsPermissions }: UseSubscriptionDetailsActionsPermissions = useSubscriptionDetailsActionsPermissions();
  const { 
    onSaveProducts,
    goBack,
    cancelProductsEdit,
    isProductAvailable,
  }: UseSubscriptionEditProductsSummary = useSubscriptionEditProductsSummary();
  const isMobile: boolean = useMediaQuery({ query: Breakpoint.Mobile });
  const currency: string = 'zł';
  useTitle(t('chic.management.subscriptionEditProductsSummaryView.title'));

  useEffect(
    (): void => {
      if (!userEditProductsData && !!subscriptionDetails) {
        redirect(RoutingPath.SubscriptionEditProducts, { subscriptionId: subscriptionDetails.id });
      }
    }, 
    [userEditProductsData, subscriptionDetails],
  );

  const isSaveAvailable: boolean = useMemo(
    (): boolean => {
      return !userEditProductsData?.productsDetails.find((product: SubscriptionPlanProductData): boolean => (
        !isProductAvailable(product.availability, product.id)
      )) 
      && !!Object.keys(userEditProductsData?.products ?? {}).length 
      && !!actionsPermissions.updateProducts;
    },
    [userEditProductsData, actionsPermissions],
  );
 
  return (
    <Container>
      <PageTitleWrapper>
        <PageTitle 
          size={PageTitleSize.Big} 
          label={t('chic.management.subscriptionEditProductsSummaryView.pageTitle')} 
          onClick={goBack}
        />
        <Button 
          label={t('chic.management.global.cancel')}
          onClick={cancelProductsEdit}
          buttonTheme={ButtonTheme.Text}
        />
      </PageTitleWrapper>
      <InnerContainer>
        {!!userEditProductsData && (
          <>
            <ProductsBox>
              {userEditProductsData.productsDetails.map((product: SubscriptionPlanProductData): JSX.Element => (
                <StyledTransactionProductBox 
                  key={product.id}
                  name={product.name}
                  category={product.category}
                  amount={userEditProductsData.products[product.id]}
                  price={product.price}
                  ean={product.ean}
                  image={product.photos[0]}
                />
              ))}
            </ProductsBox>
            <OrderSummaryBox 
              items={[
                {
                  label: t('chic.management.subscriptionEditProductsSummaryView.orderSummaryBox.value'),
                  value: `${parsePrice(userEditProductsData.simulationData.productsPrice.regular)} ${currency}`,
                },
                {
                  label: t('chic.management.subscriptionEditProductsSummaryView.orderSummaryBox.discount'),
                  value: `${parsePrice(userEditProductsData.simulationData.productsPrice.discountValue)} ${currency}`,
                  badge: userEditProductsData.simulationData.productsPrice.discountPercent 
                    ? `${userEditProductsData.simulationData.productsPrice.discountPercent}%` 
                    : undefined,
                },
                {
                  label: t('chic.management.subscriptionEditProductsSummaryView.orderSummaryBox.delivery'),
                  value: `${parsePrice(userEditProductsData.simulationData.deliveryPrice.discounted ?? 0)} ${currency}`,
                },
              ]} 
              highlightedItem={{
                label: t('chic.management.subscriptionEditProductsSummaryView.orderSummaryBox.finalValue'),
                value: `${parsePrice(userEditProductsData.simulationData.productsPrice.discounted 
                  + userEditProductsData.simulationData.deliveryPrice.discounted)} ${currency}`,
              }}              
            />
          </>
        )}
      </InnerContainer>
      <ButtonsBox>
        <Button 
          label={t('chic.management.global.goBack')} 
          buttonTheme={ButtonTheme.Secondary} 
          onClick={goBack}
          reverted={!isMobile}
          fullWidth={isMobile}
        />
        <Button 
          label={t('chic.management.global.save')} 
          buttonTheme={ButtonTheme.Primary} 
          onClick={onSaveProducts} 
          disabled={!isSaveAvailable}
          fullWidth={isMobile}
        />
      </ButtonsBox>
    </Container>
  );
};
