import React, { useEffect, useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import {
  ButtonsWrapper,
  Container,
  InnerContainer,
  LoadingWrapper,
  PageTitleButtons,
  PageTitleWrapper,
  Spacer,
  StyledPagination,
  StyledTabSwitch,
} from './adsList.styled';
import {
  Breakpoint,
  Button,
  ButtonTheme,
  ComponentColorTheme,
  EditItemsRows,
  EditItemsRowsTheme,
  EmptyListCover,
  IconName,
  Loader,
  PageTitle,
  PageTitleSize,
  PaginationDetails,
  useNotifications,
  UseNotifications,
  usePagination,
  useRedirect,
  UseRedirect,
  UseState,
} from '@chic-loyalty/ui';
import { useMediaQuery } from 'react-responsive';
import { useTitle } from '@chic/hooks';
import { useQuery } from 'react-query';
import { QueryKey, RoutingPath, SetupType, SortOrder } from '@chic/enums';
import { FrontendApiError, ListPagination, SetupListElement } from '@chic/models';
import { editSetupActivityState, getAdsSetupsList, refreshDeviceSetups } from '@chic/api';
import { isSetupType } from '@chic/guards';
import { useSearchParams } from 'react-router-dom';
import { UseSearchParams } from '@chic/types';
import { EditItemRowExtended } from './adsList.types';

export const AdsListView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { setMaxItems, pagesCount, page, setPage, itemsPerPage, offset }: PaginationDetails = usePagination();
  const { redirect }: UseRedirect = useRedirect();
  const { addToast }: UseNotifications = useNotifications();
  const [activeTab, setActiveTab]: UseState<SetupType> = useState<SetupType>(SetupType.Tablet);
  const [isLoading, setIsLoading]: UseState<boolean> = useState<boolean>(false);
  const [adsList, setAdsList]: UseState<EditItemRowExtended[] | null> = useState<EditItemRowExtended[] | null>(null);
  const isMobile: boolean = useMediaQuery({ query: Breakpoint.Mobile });
  const [searchParams, setSearchParams]: UseSearchParams = useSearchParams();
  useTitle(t('chic.management.adsListView.title'));
  const handleCheckboxChange: (id: string, value: boolean) => Promise<void> = (id: string, value: boolean): Promise<void> => {
    return editSetupActivityState(id, { active: value })
      .then((updatedSetup:  SetupListElement): void => {
        addToast({ content: t('chic.management.adsListView.adsList.updatedSuccess') });
        setAdsList((prevList: EditItemRowExtended[] | null) =>
          prevList
            ? prevList.map((item: EditItemRowExtended) =>
              item.id === updatedSetup.id
                ? { ...item, isActive: updatedSetup.active }
                : item,
            )
            : prevList,
        );
      })
      .catch((error: FrontendApiError): void => {
        addToast({ content: error.message || t('chic.management.adsListView.adsList.updateFailed') });
      });
  };

  useEffect(
    (): void => {
      const typeFromParams: string | null = searchParams.get('type');
      if (typeFromParams && isSetupType(typeFromParams)) {
        setActiveTab(typeFromParams);
      }
    },
    [searchParams],
  );

  useEffect(
    (): void => {
      setPage(1);
      setSearchParams({ search: activeTab });
    }, 
    [activeTab],
  );

  const { isLoading: adsSetupFetching } = useQuery(
    [QueryKey.AdsList, activeTab, offset, page],
    (): Promise<ListPagination<SetupListElement>> => getAdsSetupsList({
      type: activeTab,
      limit: itemsPerPage,
      order: SortOrder.Descending,
      offset,
    }),
    {
      onSuccess: (data: ListPagination<SetupListElement>): void => {
        setMaxItems(data.amount);
        setAdsList(data.list.map((setup: SetupListElement): EditItemRowExtended => ({
          id: setup.id,
          icon: IconName.PhoneOutline,
          onActivityStateChange: (value: boolean) => handleCheckboxChange(setup.id, value),
          isActive: setup.active,
          details: {
            label: t('chic.management.adsListView.adsList.configurationName'),
            value: setup.name,
          },
          buttons: [
            {
              label: t('chic.management.adsListView.adsList.edit'),
              action: (): void => redirect(RoutingPath.AdEdit, { type: setup.type, id: setup.id }),
              buttonTheme: ButtonTheme.Text,
            },
          ],
        })));
      },
      onError: (error: FrontendApiError): void => {
        setAdsList([]);
        addToast({ content: error.message ?? '' });
      },
    },
  );

  const onRefreshAnimations: () => void = (): void => {
    setIsLoading(true);

    refreshDeviceSetups()
      .then((): void => {
        addToast({ content: t('chic.management.adsListView.onRefreshAnimations.success'), icon: IconName.Check });
      })
      .catch((error: FrontendApiError): void => {
        addToast({ content: t('chic.management.adsListView.onRefreshAnimations.error', { error: error.message }) });
      })
      .finally((): void => {
        setIsLoading(false);
      });
  };

  return (
    <Container>
      <PageTitleWrapper>
        <PageTitle label={t('chic.management.adsListView.pageTitle')} size={PageTitleSize.Big} />
        {!isMobile && (
          <PageTitleButtons>
            <Button 
              buttonTheme={ButtonTheme.Text} 
              label={t('chic.management.adsListView.button.refreshAnimations')} 
              isLoading={isLoading}
              onClick={onRefreshAnimations}
            />
            <Spacer />
            <Button 
              buttonTheme={ButtonTheme.Text} 
              label={t('chic.management.adsListView.button.addNewAnimation')} 
              onClick={(): void => redirect(RoutingPath.AdAdd, { type: activeTab })}
            />
          </PageTitleButtons>
        )}
      </PageTitleWrapper>
      {adsSetupFetching
        ? (
          <LoadingWrapper>
            <Loader />
          </LoadingWrapper>
        ) : (
          <InnerContainer>
            <StyledTabSwitch
              tabs={[
                {
                  name: SetupType.Tablet,
                  labels: [t('chic.management.adsListView.tabs.tablet')],
                },
                {
                  name: SetupType.Tv,
                  labels: [t('chic.management.adsListView.tabs.tv')],
                },
              ]}
              onTabChange={(tabName: string): void => setActiveTab(tabName as SetupType)}
              initialTabName={activeTab}
            />
            {adsList !== null && (
              adsList.length ? (
                <EditItemsRows
                  rows={adsList}
                  theme={EditItemsRowsTheme.Edit}
                />
              ) : (
                <EmptyListCover
                  header={t('chic.management.adsListView.emptyList.header')}
                  subheader={t('chic.management.adsListView.emptyList.subheader')}
                />
              )
            )}
            {pagesCount > 1 && (
              <StyledPagination
                activePage={page}
                pagesCount={pagesCount}
                onActivePageChange={setPage}
                colorTheme={ComponentColorTheme.IC}
              />
            )}
          </InnerContainer>
        )
      }
      {isMobile && (
        <ButtonsWrapper>
          <Button 
            buttonTheme={ButtonTheme.Primary} 
            label={t('chic.management.adsListView.button.refreshAnimations')} 
            onClick={onRefreshAnimations}
            isLoading={isLoading}
            fullWidth
          />
          <Button 
            buttonTheme={ButtonTheme.Secondary} 
            label={t('chic.management.adsListView.button.addNewAnimation')} 
            onClick={(): void => redirect(RoutingPath.AdAdd, { type: activeTab })}
            fullWidth
          />
        </ButtonsWrapper>
      )}
    </Container>
  );
};
