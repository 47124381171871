import { GoalsContext } from '@chic/contexts';
import { GoalsState, UseGoalsContext } from '@chic/models';
import { useContext } from 'react';

// TODO: add more in feature MRs
export const useGoalsContext: () => UseGoalsContext = (): UseGoalsContext => {
  const contextData: GoalsState = useContext(GoalsContext);

  return { 
    goalsDictionary: contextData.goalsDictionary,
    goalDetails: contextData.goalDetails,
  };
};
