import React, { useState } from 'react';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { TransProps, useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { changePassword } from '@chic/api';
import { FrontendApiError } from '@chic/models';
import { ErrorMessageType } from '@chic/enums';

import {
  Container,
  StyledPageTitle,
  ButtonBox,
  InputsWrapper,
} from './passwordChange.styled';
import { PasswordChangeFormData } from './passwordChange.types';
import { 
  Button, 
  ButtonTheme, 
  ComponentColorTheme, 
  Input, 
  InputTheme, 
  UseNotifications, 
  UseState, 
  ValidationBar, 
  ValidationBarTheme, 
  useNotifications, 
} from '@chic-loyalty/ui';
import { usePasswordChangeValidation } from './passwordChange.hooks';

export const PasswordChange: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const [infoBoxText, setInfoBoxText]: UseState<string | null> = useState<string | null>(null);
  const [isButtonLoading, setIsButtonLoading]: UseState<boolean> = useState<boolean>(false);
  const { addToast }: UseNotifications = useNotifications();
  const ChangePasswordValidationSchema: Yup.Schema<PasswordChangeFormData> = usePasswordChangeValidation();

  const handleChangePassword: (values: PasswordChangeFormData, formikHelpers: FormikHelpers<PasswordChangeFormData>) => void = (
    values: PasswordChangeFormData, formikHelpers: FormikHelpers<PasswordChangeFormData>,
  ): void => {
    setIsButtonLoading(true);
    changePassword({ oldPassword: values.oldPassword, password: values.newPasswordFirst })
      .then((): void => {
        formikHelpers.resetForm();
        setInfoBoxText(t('chic.crmApp.passwordChange.changePasswordSuccess'));
      })
      .catch((error: FrontendApiError): void => {
        setInfoBoxText(null);
        if (error.errors.password) {
          addToast({
            content: error.errors.password.enum === ErrorMessageType.PasswordHistorySame
              ? t('chic.crmApp.errors.password_history_same')
              : t('chic.crmApp.errors.password_not_valid'),
          });
        }
        if (error.errors.oldPassword?.enum === ErrorMessageType.PasswordInvalidCurrent) {
          addToast({ content: t('chic.crmApp.errors.password_invalid_current') });
        }
      })
      .finally((): void => setIsButtonLoading(false));
  };

  return (
    <Container>
      <StyledPageTitle label={t('chic.crmApp.passwordChange.title')} />
      <Formik
        validationSchema={ChangePasswordValidationSchema}
        initialValues={{ oldPassword: '', newPasswordFirst: '', newPasswordSecond: '' }}
        onSubmit={handleChangePassword}
      >
        {({ setFieldValue, errors, touched, values }: FormikProps<PasswordChangeFormData>): JSX.Element => (
          <Form>
            <InputsWrapper>
              <Input
                onChange={(value: string): void => setFieldValue('oldPassword', value)}
                label={t('chic.crmApp.passwordChange.oldPassword.label')}
                type='password'
                placeholder={t('chic.crmApp.passwordChange.oldPassword.placeholder')}
                value={values.oldPassword}
                description={touched.oldPassword ? errors.oldPassword : undefined}
                inputTheme={errors.oldPassword && touched.oldPassword ? InputTheme.Error : InputTheme.Standard}
                required
              />
              <Input
                onChange={(value: string): void => setFieldValue('newPasswordFirst', value)}
                label={t('chic.crmApp.passwordChange.newPasswordFirst.label')}
                type='password'
                placeholder={t('chic.crmApp.passwordChange.newPasswordFirst.placeholder')}
                value={values.newPasswordFirst}
                description={touched.newPasswordFirst ? errors.newPasswordFirst : undefined}
                inputTheme={errors.newPasswordFirst && touched.newPasswordFirst ? InputTheme.Error : InputTheme.Standard}
                required
              />
              <Input
                onChange={(value: string): void => setFieldValue('newPasswordSecond', value)}
                label={t('chic.crmApp.passwordChange.newPasswordSecond.label')}
                type='password'
                placeholder={t('chic.crmApp.passwordChange.newPasswordSecond.placeholder')}
                value={values.newPasswordSecond}
                description={touched.newPasswordSecond ? errors.newPasswordSecond : undefined}
                inputTheme={errors.newPasswordSecond && touched.newPasswordSecond ? InputTheme.Error : InputTheme.Standard}
                required
              />
            </InputsWrapper>
            <ButtonBox>
              <Button 
                type='submit' 
                buttonTheme={ButtonTheme.Primary} 
                label={t('chic.crmApp.global.save')} 
                isLoading={isButtonLoading} 
              />
            </ButtonBox>
          </Form>
        )}
      </Formik>
      {!!infoBoxText && <ValidationBar message={infoBoxText} barTheme={ValidationBarTheme.Red} colorTheme={ComponentColorTheme.IC} />}
    </Container>
  );
};
