import React, { useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { 
  AccordionChildrenContainer, 
  AccordionChildrenInnerContainer, 
  ButtonsContainer, 
  Container, 
  DetailsEditBoxes, 
  InnerContainer, 
  PageTitleWrapper, 
  ScrollToTopButton, 
  StyledEmptyListCover, 
  StyledPagination,
  StyledTabSwitch,
  ValueBoxContainer, 
} from './refundsList.styled';
import { 
  AccordionTable, 
  AccordionTableHeadCell, 
  AccordionTableRowSettings, 
  Breakpoint, 
  Button, 
  ButtonTheme, 
  Color, 
  ComponentColorTheme, 
  DetailsEditBox, 
  Icon, 
  IconName, 
  PageTitle, 
  PageTitleSize, 
  PaginationDetails, 
  TabSwitchOption, 
  TextAlign, 
  useNotifications, 
  UseNotifications, 
  usePagination, 
  useParsers, 
  UseParsers, 
  useRedirect, 
  UseRedirect, 
  UseState,
  ValueBox,
} from '@chic-loyalty/ui';
import { useStatics, useTitle } from '@chic/hooks';
import { 
  FrontendApiError,
  ListPagination, 
  RefundListElement, 
  SubscriptionDeliveryExtended, 
  SubscriptionPaymentExtended,
  UseStatics, 
} from '@chic/models';
import { acceptRefund, getRefundsList } from '@chic/api';
import { FileFromViews, QueryKey, RefundsStatus, SubscriptionOrderStatus } from '@chic/enums';
import { QueryClient, useQuery, useQueryClient } from 'react-query';
import { statusFilterTabs } from './refundsList.const';
import { useMediaQuery } from 'react-responsive';
import { RefundsTableName } from './refundsList.enum';
import { orderStatusColor } from '@chic/constans';

export const RefundsListView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { setMaxItems, pagesCount, page, setPage, itemsPerPage, offset }: PaginationDetails = usePagination();
  const { parsePrice }: UseParsers = useParsers();
  const { addToast, showFullscreenAlert, hideFullscreenAlert }: UseNotifications = useNotifications();
  const { redirect }: UseRedirect = useRedirect();
  const { getPaymentNameByType, getOrderStatusByType, orderStatuses }: UseStatics = useStatics();
  const [accordionTableRows, setAccordionTableRows]: UseState<AccordionTableRowSettings[] | null> 
    = useState<AccordionTableRowSettings[] | null>(null);
  const [accordionTableHeaders, setAccordionTableHeaders]: UseState<AccordionTableHeadCell[]> = useState<AccordionTableHeadCell[]>([]);
  const [selectedOrders, setSelectedOrders]: UseState<number[]> = useState<number[]>([]);
  const [activeTab, setActiveTab]: UseState<RefundsStatus | undefined> 
    = useState<RefundsStatus | undefined>(undefined);
  const queryClient: QueryClient = useQueryClient();
  const currency: string = 'zł';
  const isMobile: boolean = useMediaQuery({ query: Breakpoint.Mobile });
  const isCustomDesktop: boolean = useMediaQuery({ query: Breakpoint.CustomDesktop });
  useTitle(t('chic.management.refundsListView.title'));

  const onAcceptRefunds: (orderIds: number[]) => void = (orderIds: number[]): void => {
    const ordersLabel: string = orderIds.join(', ');

    showFullscreenAlert({
      title: t('chic.management.refundsListView.acceptRefund.alert.title'),
      description: t('chic.management.refundsListView.acceptRefund.alert.description', { ordersLabel }),
      acceptButtonSettings: {
        label: t('chic.management.global.yes'),
        action: (): void => {
          orderIds.forEach((orderId: number): void => {
            acceptRefund(orderId)
              .then((): void => {
                addToast({ content: t('chic.management.refundsListView.onAcceptRefunds.success', { id: orderId }) });
              })
              .catch((error: FrontendApiError): void => {
                addToast({ content: `${t('chic.management.refundsListView.onAcceptRefunds.error', { id: orderId })} ${error.message}` });
              });
          });
          void queryClient.invalidateQueries(QueryKey.RefundsList); 
          setSelectedOrders([]);
          hideFullscreenAlert();
        },
      },
      cancelButtonSettings: {
        label: t('chic.management.global.no'),
        action: hideFullscreenAlert,
      },
    });
  };

  const onSelectOrder: (checked: boolean, orderId: number) => void = (checked: boolean, orderId: number): void => {
    setSelectedOrders((prevSelectedOrders: number[]): number[] =>
      checked
        ? [...prevSelectedOrders, orderId]
        : prevSelectedOrders.filter((id: number): boolean => id !== orderId),
    );
  };

  const handleTabChange: (tabName: string) => void = (tabName: string): void => {
    setActiveTab(tabName as RefundsStatus);
  };

  useQuery(
    [QueryKey.RefundsList, orderStatuses, offset, itemsPerPage, activeTab],
    (): Promise<ListPagination<RefundListElement>> => getRefundsList({
      limit: itemsPerPage,
      status: activeTab,
      offset,
    }),
    {
      onSuccess: (data: ListPagination<RefundListElement>): void => {
        setMaxItems(data.amount);
        const accordionHeaders: AccordionTableHeadCell[] = [
          { name: RefundsTableName.Id },
          {
            name: RefundsTableName.OrderId,
            label: t('chic.management.refundsListView.accordionHeaders.orderId'),
          },
          ...(!isMobile && isCustomDesktop ? [] : [{
            name: RefundsTableName.Price,
            label: t('chic.management.refundsListView.accordionHeaders.price'),
            textAlign: TextAlign.Center,
          }]),
          {
            name: RefundsTableName.FullPrice,
            label: t('chic.management.refundsListView.accordionHeaders.fullPrice'),
            textAlign: TextAlign.Center,
          },
          {
            name: RefundsTableName.FullName,
            label: t('chic.management.refundsListView.accordionHeaders.fullName'),
          },
          {
            name: RefundsTableName.Email,
            label: t('chic.management.refundsListView.accordionHeaders.email'),
          },
          {
            name: RefundsTableName.Status,
            label: t('chic.management.refundsListView.accordionHeaders.status'),
          },
        ];
        setAccordionTableHeaders(accordionHeaders);
        setAccordionTableRows(data.list.map((element: RefundListElement): AccordionTableRowSettings => {
          const lastDelivery: SubscriptionDeliveryExtended | undefined = element.deliveries.length 
            ? element.deliveries[element.deliveries.length - 1] 
            : undefined;
          const lastPayment: SubscriptionPaymentExtended | undefined = element.payments.length 
            ? element.payments[element.payments.length - 1] 
            : undefined;

          return {
            cells: [
              {
                name: RefundsTableName.Id,
                onSelect: (name: string, checked: boolean): void => onSelectOrder(checked, element.orderId),
                checkboxDisabled: element.status !== SubscriptionOrderStatus.RefundRequest,
              },
              {
                name: RefundsTableName.OrderId,
                value: String(element.orderId),
              },
              ...(!isMobile && isCustomDesktop ? [] : [{
                name: RefundsTableName.Price,
                value: `${parsePrice(element.productsPrice)} ${currency}`,
                textAlign: TextAlign.Center,
              }]),
              {
                name: RefundsTableName.FullPrice,
                value: `${parsePrice(element.productsPrice + element.deliveryCost)} ${currency}`,
                color: Color.ICYellow100,
                textAlign: TextAlign.Center,
              },
              {
                name: RefundsTableName.FullName,
                value: `${element.customer.name} ${element.customer.surname}`,
                wordWrap: true,
              },
              {
                name: RefundsTableName.Email,
                value: element.customer.email ?? t('chic.management.global.noData'),
                wordWrap: true,
              },
              {
                name: RefundsTableName.Status,
                value: getOrderStatusByType(element.status),
                color: orderStatusColor[element.status],
              },
            ],
            headers: accordionHeaders,
            accordionChildren: (
              <AccordionChildrenContainer>
                <AccordionChildrenInnerContainer>
                  <ValueBoxContainer>
                    <ValueBox 
                      items={[
                        {
                          label: t('chic.management.refundsListView.valueBox.orderId'),
                          value: String(element.orderId),
                        },
                        {
                          label: t('chic.management.refundsListView.valueBox.subscriptionId'),
                          value: String(element.subscriptionId),
                        },
                      ]} 
                      image={FileFromViews.RefundsListViewValueBoxBackground}              
                    />
                  </ValueBoxContainer>
                  <DetailsEditBoxes>
                    <DetailsEditBox 
                      title={t('chic.management.refundsListView.detailsEditBox.payment.title')} 
                      rows={[
                        {
                          textContent: lastPayment?.type ? getPaymentNameByType(lastPayment.type) : t('chic.management.global.noData'),
                          badge: lastPayment?.externalId ?? undefined,
                        },
                      ]}     
                    />
                    <DetailsEditBox 
                      title={t('chic.management.refundsListView.detailsEditBox.delivery.title')} 
                      buttonSettings={lastDelivery?.tracking?.url ? {
                        label: t('chic.management.refundsListView.detailsEditBox.delivery.track'),
                        onClick: (): void => redirect(lastDelivery.tracking.url ?? '/', undefined, true, true),
                      } : undefined}
                      rows={[
                        {
                          textContent: lastDelivery?.deliveryDestination.type.name ?? t('chic.management.global.noData'),
                          badge: lastDelivery?.cost === 0 
                            ? t('chic.management.refundsListView.detailsEditBox.delivery.freeDelivery') 
                            : undefined,
                        },
                      ]}  
                    />
                  </DetailsEditBoxes>
                </AccordionChildrenInnerContainer>
                {element.status === SubscriptionOrderStatus.RefundRequest && (
                  <Button 
                    label={t('chic.management.refundsListView.acceptRefund')} 
                    onClick={(): void => onAcceptRefunds([element.orderId])} 
                    buttonTheme={ButtonTheme.Primary}
                  />
                )}
              </AccordionChildrenContainer>
            ),
            mobileButtonSettings: element.status === SubscriptionOrderStatus.RefundRequest ? {
              label: t('chic.management.refundsListView.acceptRefund'),
              onClick: (): void => onAcceptRefunds([element.orderId]),
            } : undefined,
          };
        }));
      },
      onError: (error: FrontendApiError): void => {
        addToast({ content: error.message ?? '' });
        setAccordionTableRows([]);
      },
    },
  );
  
  return (
    <Container>
      <PageTitleWrapper>
        <PageTitle size={PageTitleSize.Big} label={t('chic.management.refundsListView.pageTitle')} />
      </PageTitleWrapper>
      <InnerContainer>
        <StyledTabSwitch 
          tabs={statusFilterTabs.map((tab: TabSwitchOption): TabSwitchOption => ({
            ...tab,
            labels: tab.labels.map((label: string): string => t(label)),
          }))}
          onTabChange={handleTabChange}
          initialTabName={RefundsStatus.All}
        />
        {accordionTableRows !== null && (
          accordionTableRows.length ? (
            <>
              <AccordionTable 
                headers={accordionTableHeaders} 
                rows={accordionTableRows}        
              />
              <StyledPagination 
                activePage={page} 
                pagesCount={pagesCount} 
                onActivePageChange={setPage} 
                colorTheme={ComponentColorTheme.IC} 
              />
            </>
          ) : (
            <StyledEmptyListCover 
              header={t('chic.management.refundsListView.emptyList.header')} 
              subheader={t('chic.management.refundsListView.emptyList.subheader')} 
            />
          )
        )}
      </InnerContainer>
      {!isMobile && (
        <ButtonsContainer>
          <Button 
            buttonTheme={ButtonTheme.Primary}
            label={t('chic.management.refundsListView.acceptMultiRefunds')}
            onClick={(): void => onAcceptRefunds(selectedOrders)}
            disabled={!selectedOrders.length}
          />
        </ButtonsContainer>
      )}
      {isMobile && (
        <ScrollToTopButton>
          <Icon name={IconName.ArrowUp} size={20} color={Color.ICYellow100} />
          {t('chic.management.global.scrollToTop')}
        </ScrollToTopButton>
      )}
    </Container>
  );
};
