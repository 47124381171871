import { Breakpoint, Color, FontWeight, Gradient, Pagination, SimpleDetailsBox, StyledComponent } from '@chic-loyalty/ui';
import styled from 'styled-components';

export const InnerContainer: StyledComponent<'div'> = styled.div`
  flex: 1;
  width: 100%;
  padding: 48px 24px 68px;
  display: flex;
  flex-direction: column;
  gap: 48px;

  @media ${Breakpoint.Mobile} {
    padding: 74px 20px 68px;
  }
`;

export const Section: StyledComponent<'div'> = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const DetailsBox: StyledComponent<'div'> = styled.div`
  display: flex;
  gap: 24px;

  @media ${Breakpoint.Mobile} {
    flex-direction: column;
    gap: 12px;
  }
`;

export const GoalNameBox: StyledComponent<'div'> = styled.div`
  border: 1px solid ${Color.ICBlack100};
  background: ${Gradient.Dark};
  padding: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
  width: 50%;

  @media ${Breakpoint.Mobile} {
    width: 100%;
  }
`;

export const StyledSimpleDetailsBox: StyledComponent<typeof SimpleDetailsBox> = styled(SimpleDetailsBox)`
  width: 50%;

  @media ${Breakpoint.Mobile} {
    width: 100%;
  }
`;

export const GoalNameLabelBox: StyledComponent<'div'> = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const GoalLabel: StyledComponent<'p'> = styled.p`
  font-weight: ${FontWeight.ExtraBold};
  font-size: 14px;
  line-height: 18px;
  color: ${Color.ICWhite100};
`;

export const GoalName: StyledComponent<'p'> = styled.p`
  font-weight: ${FontWeight.Bold};
  font-size: 14px;
  line-height: 18px;
  color: ${Color.ICYellow100};
  width: 100%;
`;

export const ProgressBarBox: StyledComponent<'div'> = styled.div`
  min-width: 300px;

  @media ${Breakpoint.CustomDesktop} {
    min-width: 200px;
  }

  @media ${Breakpoint.Mobile} {
    min-width: 100%;
    width: 100%;
    flex: 1;
  }
`;

export const LoaderContainer: StyledComponent<'div'> = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledPagination: StyledComponent<typeof Pagination> = styled(Pagination)`
  margin: 32px 0 0;
`;

export const BadgeWithNameChildren: StyledComponent<'div'> = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
`;

export const NameChildren: StyledComponent<'p'> = styled.p`
  font-size: 14px;
  line-height: 20px;
  color: ${Color.ICWhite100};
`;
