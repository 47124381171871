import React, { useMemo } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { Params, useParams } from 'react-router-dom';
import { 
  AddFileInitialData,
  AddFileTheme, 
  ButtonTheme, 
  EditItemRow, 
  FileSettingsBox, 
  FileSettingsBoxesTheme,
  IconName,
  Loader, 
  useRedirect, 
  UseRedirect, 
} from '@chic-loyalty/ui';
import { 
  AddFileTabId,
  AdScreenType,
  FileTypeExtended, 
  RoutingPath,
  SetupType, 
  UploadAssetType, 
} from '@chic/enums';
import { ScreenDetails, ScreenNodeDetails, ScreenObject, UseAnimations, UsePlaylistAnimation } from '@chic/models';
import { BaseViewForAnimations } from '@chic/components';
import { Container } from './adEdit.styled';
import { useAnimations, usePlaylistAnimation } from '@chic/hooks';
import { stringify } from 'query-string';

export const AdEditView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { type, id }: Params = useParams();
  const { redirect }: UseRedirect = useRedirect();
  const { 
    onAddFile, 
    onSaveFile, 
    onSetAnimationName, 
    animationName, 
    requirements, 
    savedFiles, 
    screens,
    targetOptions,
    acceptedFilesTypes,
    onSavedFilesChangePositions,
    onDeleteSavedFile,
    onEditSetup,
    resetUploadedData,
    onEditFile,
    fileToEdit,
    onAddNewItem,
    buttonBanners,
    onChangeSelectedTargets,
    selectedTargets,
    onDeleteScreen,
    transformAssetDetailsToAddFileInitial,
    setupType,
    uploadErrorMessage,
    setUploadedFileSettings,
    isEditSetupLoading,
    setOpenFileDialog,
  }: UseAnimations = useAnimations(
    type as SetupType, 
    type === SetupType.Tv ? UploadAssetType.TVAnimation : UploadAssetType.TabletAnimation,
    undefined,
    id,
  );
  const { 
    onAddPlaylistCover, 
    onAddPlaylistFile, 
    onSavePlaylistElement, 
    resetUploadedCover,
    resetUploadedFile,
    savedPlaylistScreens,
    savedPlaylistThumbnails,
    uploadCoverError,
    uploadFileError,
    onChangePlaylistElementSettings,
    onAddNewPlaylistItem,
    onDeletePlaylistScreen,
    onEditPlaylistScreen,
    onSavedScreensChangePositions,
    playlistAcceptedFilesTypes,
    playlistRequirements,
    transformScreenDetailsToAddFileInitials,
    screenToEdit,
  }: UsePlaylistAnimation = usePlaylistAnimation();

  const transformedScreenToInitialData: AddFileInitialData[] | undefined = useMemo(
    (): AddFileInitialData[] | undefined => {
      return screenToEdit ? transformScreenDetailsToAddFileInitials(screenToEdit) : undefined;
    },
    [screenToEdit],
  );

  const resetPlaylistState: boolean = useMemo(
    (): boolean => {
      return !!resetUploadedCover && !!resetUploadedFile;
    },
    [resetUploadedCover, resetUploadedFile],
  );

  const transformedFileToInitialData: AddFileInitialData | undefined = useMemo(
    (): AddFileInitialData | undefined => {
      return fileToEdit ? transformAssetDetailsToAddFileInitial(fileToEdit) : undefined;
    },
    [fileToEdit],
  );

  return (
    <Container>
      {!setupType ? <Loader /> : (
        <BaseViewForAnimations 
          title={t(`chic.management.adEditView.${setupType}.title`)} 
          goBack={(): void => redirect(`${RoutingPath.AdsList}?${stringify({ type: setupType }, { skipEmptyString: true })}`)} 
          animationName={animationName}
          onNameChange={onSetAnimationName} 
          addFileProps={{
            setOpenFileDialog,
            tabs: [
              {
                title: t('chic.management.global.addFile'),
                id: AddFileTabId.ScreensaverAnimation,
              },
            ],
            items: [
              {
                addFile: onAddFile,
                theme: type === SetupType.Tv ? AddFileTheme.TVAd : AddFileTheme.TabletAd,
                requirements,
                acceptedFilesTypes,
                maxImageSizeInBytes: 2 * 1024 * 1024,
                maxVideoSizeInBytes: 15 * 1024 * 1024,
                withDurationChange: setupType === SetupType.Tv
                  ? 'always'
                  : 'conditionally',
                resetData: resetUploadedData,
                initialData: transformedFileToInitialData,
                errorMessage: uploadErrorMessage,
                id: AddFileTabId.ScreensaverAnimation,
                onChange: setUploadedFileSettings,
              },
            ],
            saveFiles: onSaveFile,
          }} 
          fileSettingsBoxesProps={{
            title: t('chic.management.adEditView.fileSettingsBoxesProps.title'),
            description: t('chic.management.adEditView.fileSettingsBoxesProps.description'),
            items: savedFiles.map((savedFile: ScreenNodeDetails): FileSettingsBox => ({
              name: savedFile.name,
              path: savedFile.value,
              isVideo: savedFile.type === FileTypeExtended.Video,
              id: savedFile.id,
            })),
            onAddNewItem,
            onItemDelete: onDeleteSavedFile,
            onItemEdit: onEditFile,
            onPositionChange: onSavedFilesChangePositions,
            theme: setupType === SetupType.Tv ? FileSettingsBoxesTheme.Horizontal : FileSettingsBoxesTheme.Vertical,
            addNewButtonLabel: t('chic.management.adEditView.fileSettingsBoxesProps.addNewButtonLabel'),
          }} 
          playlistSettings={type === SetupType.Tablet ? {
            tabs: [
              {
                title: t('chic.management.adEditView.playlistSettings.tabs.cover.title'),
                id: AddFileTabId.PlaylistTabletButton,
              },
              {
                title: t('chic.management.adEditView.playlistSettings.tabs.video.title'),
                id: AddFileTabId.PlaylistTabletAnimation,
              },
            ],
            items: [
              {
                id: AddFileTabId.PlaylistTabletButton,
                addFile: onAddPlaylistCover,
                theme: AddFileTheme.TabletButton,
                requirements: playlistRequirements[AddFileTheme.TabletButton],
                acceptedFilesTypes: playlistAcceptedFilesTypes[AddFileTheme.TabletButton],
                maxImageSizeInBytes: 2 * 1024 * 1024,
                withDurationChange: 'never',
                resetData: resetPlaylistState,
                errorMessage: uploadCoverError,
                withTextbox: true,
                onChange: onChangePlaylistElementSettings,
                initialData: transformedScreenToInitialData?.[0],
              },
              {
                id: AddFileTabId.PlaylistTabletAnimation,
                addFile: onAddPlaylistFile,
                theme: AddFileTheme.TabletAd,
                requirements: playlistRequirements[AddFileTheme.TabletAd],
                acceptedFilesTypes: playlistAcceptedFilesTypes[AddFileTheme.TabletAd],
                maxVideoSizeInBytes: 15 * 1024 * 1024,
                withDurationChange: 'never',
                resetData: resetPlaylistState,
                errorMessage: uploadFileError,
                initialData: transformedScreenToInitialData?.[1],
              },
            ],
            saveFiles: onSavePlaylistElement,
          } : undefined}
          playlistFiles={type === SetupType.Tablet ? {
            title: t('chic.management.adAddView.fileSettingsBoxesProps.title'),
            description: t('chic.management.adAddView.fileSettingsBoxesProps.description'),
            items: savedPlaylistScreens.map((playlistScreen: ScreenDetails): FileSettingsBox => {
              const thumbnail: string | undefined = savedPlaylistThumbnails.get(playlistScreen.id);
              // TODO: fix when unions will be ready
              const coverText: string | undefined = (playlistScreen.nodes
                .find((node: ScreenNodeDetails | ScreenDetails): boolean => node.type === FileTypeExtended.Text) as ScreenNodeDetails)
                .value;
              return {
                id: playlistScreen.id,
                name: playlistScreen.name,
                path: thumbnail ?? '',
                coverText,
              };
            }),
            onAddNewItem: onAddNewPlaylistItem,
            onItemDelete: onDeletePlaylistScreen,
            onItemEdit: onEditPlaylistScreen,
            onPositionChange: onSavedScreensChangePositions,
            theme: FileSettingsBoxesTheme.HorizontalLong,
            addNewButtonLabel: t('chic.management.adAddView.fileSettingsBoxesProps.addNewButtonLabel'),
          } : undefined}
          nameInputLabel={t('chic.management.adEditView.nameInputLabel')} 
          buttonBanners={buttonBanners}
          itemPickerSettings={{
            title: t('chic.management.adEditView.buttonBanner.itemPickerSettings.title'),
            options: targetOptions,
            onDelete: onChangeSelectedTargets,
            onSelect: onChangeSelectedTargets,
            initialTargets: selectedTargets,
          }}
          screensRows={screens
            .filter((screen: ScreenObject): boolean => [AdScreenType.AnimationsSlider, AdScreenType.CategoriesList].includes(screen.type))
            .map((screen: ScreenObject): EditItemRow => ({
              icon: IconName.Ads,
              details: {
                label: t('chic.management.adEditView.buttonBanner.screensRows.label'),
                value: screen.name,
              },
              buttons: [
                {
                  label: t('chic.management.adEditView.buttonBanner.screensRows.edit'),
                  onClick: (): void => redirect(RoutingPath.AdEditScreen, { type: setupType, screenType: screen.type, id: screen.id }),
                  buttonTheme: ButtonTheme.TextWhite,
                },
                {
                  label: t('chic.management.adEditView.buttonBanner.screensRows.delete'),
                  onClick: (): void => onDeleteScreen(screen.id),
                  buttonTheme: ButtonTheme.TextBlue,
                },
              ],
            }))}
          acceptButtonSettings={{
            label: t('chic.management.global.save'),
            action: onEditSetup,
            isLoading: isEditSetupLoading,
            disabled: !animationName || !savedFiles.length || !selectedTargets.length,
          }} 
          cancelButtonSettings={{
            label: t('chic.management.global.cancel'),
            action: (): void => redirect(`${RoutingPath.AdsList}?${stringify({ type: setupType }, { skipEmptyString: true })}`),
          }}
          withBackgroundImage
        />
      )}
    </Container>
  );
};
