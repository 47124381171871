import { useGoalsContext, useGoalsStatics, useTitle } from '@chic/hooks';
import { TransProps, useTranslation } from 'react-i18next';
import { GoalRealizationDetails, UseGoalsContext, UseGoalsStatics } from '@chic/models';
import React, { useEffect, useMemo, useState } from 'react';
import { 
  BadgeWithNameChildren,
  DetailsBox, 
  GoalLabel, 
  GoalName, 
  GoalNameBox, 
  GoalNameLabelBox, 
  InnerContainer, 
  LoaderContainer, 
  NameChildren, 
  ProgressBarBox,
  Section, 
  StyledPagination, 
  StyledSimpleDetailsBox, 
} from './goalDetails.styled';
import { 
  Badge,
  BadgeSize,
  Breakpoint,
  Button, 
  ButtonTheme, 
  Color, 
  ComponentColorTheme, 
  DetailsRowTheme, 
  DetailsTable, 
  DetailsTableCellType, 
  DetailsTableMobileTheme, 
  DetailsTableRow, 
  Icon, 
  IconName, 
  Loader, 
  MenuPosition, 
  PageTitle, 
  PageTitleSize, 
  PaginationDetails, 
  ProgressBar, 
  SimpleDetailsBoxItem, 
  TextAlign, 
  useFormatDate, 
  UseFormatDate, 
  usePagination, 
  useParsers, 
  UseParsers, 
  useRedirect,
  UseRedirect,
  UseState,
  VisibilitySettings, 
} from '@chic-loyalty/ui';
import { BaseViewForGoals } from '@chic/components';
import { FileFromViews, RoutingPath } from '@chic/enums';
import { TargetListColumnName } from './goalDetails.enum';
import { useMediaQuery } from 'react-responsive';

export const GoalDetailsView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { goalDetails }: UseGoalsContext = useGoalsContext();
  const { redirect }: UseRedirect = useRedirect();
  const { formatDate }: UseFormatDate = useFormatDate();
  const { parsePrice }: UseParsers = useParsers();
  const { getGoalTypeLabel }: UseGoalsStatics = useGoalsStatics();
  const { setMaxItems, pagesCount, page, setPage, itemsPerPage, offset }: PaginationDetails = usePagination();
  const [targetsList, setTargetsList]: UseState<DetailsTableRow[]> = useState<DetailsTableRow[]>([]);
  const currency: string = 'zł';
  const isMobile: boolean = useMediaQuery({ query: Breakpoint.Mobile });
  useTitle(t('chic.management.goalDetailsView.title'));

  useEffect(
    (): void => {
      if (!goalDetails) {
        return;
      }

      setMaxItems(goalDetails.realizations.length);
      setTargetsList(goalDetails.realizations
        .slice(offset, page * itemsPerPage)
        .map((realization: GoalRealizationDetails): DetailsTableRow => ({
          theme: DetailsRowTheme.Normal,
          cells: [
            {
              name: TargetListColumnName.ID,
              cellType: DetailsTableCellType.Children,
              customAttributes: {
                children: (
                  <Badge 
                    label={realization?.storeExternalId ?? '-'} 
                    size={BadgeSize.Small} 
                    colorTheme={{ background: Color.ICBlue, font: Color.ICBlack500 }}
                  />
                ),
              },
              visibilitySettings: VisibilitySettings.OnlyDesktop,
            },
            {
              name: TargetListColumnName.NameWithId,
              customAttributes: {
                children: (
                  <BadgeWithNameChildren>
                    <Badge 
                      label={realization?.storeExternalId ?? '-'} 
                      size={BadgeSize.Small} 
                      colorTheme={{ background: Color.ICBlue, font: Color.ICBlack500 }}
                    />
                    <NameChildren>{realization?.storeName ?? '-'}</NameChildren>
                  </BadgeWithNameChildren>
                ),
              },
              cellType: DetailsTableCellType.Children,
              visibilitySettings: VisibilitySettings.OnlyMobile,
            },
            {
              name: TargetListColumnName.Name,
              value: realization?.storeName ?? '-',
              visibilitySettings: VisibilitySettings.OnlyDesktop,
            },
            {
              name: TargetListColumnName.Award,
              value: `${parsePrice(realization?.awardValue ?? 0)} ${currency}`,
              iconDetails: {
                icon: IconName.Bill,
                iconColor: Color.ICYellow100,
              },
            },
            {
              name: TargetListColumnName.Realization,
              value: `${parsePrice(realization?.realization ?? 0)} / ${parsePrice(realization?.goalValue ?? 0)} ${currency}`,
              iconDetails: {
                icon: IconName.Bill,
                iconColor: Color.ICGreen,
              },
            },
            {
              name: TargetListColumnName.Progress,
              cellType: DetailsTableCellType.Children,
              textAlign: TextAlign.Right,
              customAttributes: {
                children: (
                  <ProgressBarBox>
                    <ProgressBar percentage={Math.floor(((realization?.realization ?? 0) / (realization?.goalValue ?? 0)) * 100)} />
                  </ProgressBarBox>
                ),
              },
            },
          ],
        })));
    },
    [goalDetails, page],
  );

  const goalSimpleDetails: SimpleDetailsBoxItem[] = useMemo(
    (): SimpleDetailsBoxItem[] => {
      if (!goalDetails) {
        return [];
      }

      const dateFrom: Date = new Date(goalDetails.dateFrom);
      const dateTo: Date = new Date(goalDetails.dateTo);

      return [
        {
          label: t('chic.management.goalDetailsView.goalSimpleDetails.status.label'),
          value: t(`chic.management.global.${goalDetails.active ? 'active' : 'inactive'}`),
          icon: IconName.SmallInfo,
        },
        {
          label: t('chic.management.goalDetailsView.goalSimpleDetails.type.label'),
          value: getGoalTypeLabel(goalDetails.type),
          icon: IconName.Goals,
        },
        {
          label: t('chic.management.goalDetailsView.goalSimpleDetails.date.label'),
          value: `${formatDate(dateFrom.getTime(), 'DD.MM.YYYY')} - ${formatDate(dateTo.getTime(), 'DD.MM.YYYY')}`,
          icon: IconName.Calendar,
        },
      ];
    },
    [goalDetails, getGoalTypeLabel],
  );

  if (!goalDetails) {
    return (
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    );
  }

  return (
    <BaseViewForGoals
      pageTitle={t('chic.management.goalDetailsView.pageTitle')}
      pageTitleChildren={
        <MenuPosition action={(): void => redirect(RoutingPath.GoalEdit, { goalId: goalDetails.id })}>
          <Button 
            label={t('chic.management.goalDetailsView.goToGoalEdit')} 
            buttonTheme={isMobile ? ButtonTheme.Text : ButtonTheme.Primary}
          />
        </MenuPosition>
      }
      background={FileFromViews.GoalDetailsViewBackground}
      backgroundMobile={FileFromViews.GoalDetailsViewBackgroundMobile}
      goBackAction={(): void => redirect(RoutingPath.GoalsList)}
    >
      <InnerContainer>
        <Section>
          <PageTitle size={PageTitleSize.Small} label={t('chic.management.goalDetailsView.goalDetails.title')} />
          <DetailsBox>
            <GoalNameBox>
              <GoalNameLabelBox>
                <Icon name={IconName.TriangleShape} size={16} color={Color.ICYellow100} />
                <GoalLabel>{t('chic.management.goalDetailsView.goalDetails.goalLabel')}</GoalLabel>
              </GoalNameLabelBox>
              <GoalName>{goalDetails.name}</GoalName>
            </GoalNameBox>
            <StyledSimpleDetailsBox items={goalSimpleDetails} />
          </DetailsBox>
        </Section>
        <Section>
          <PageTitle size={PageTitleSize.Small} label={t('chic.management.goalDetailsView.targetsList.title')} />
          {!!targetsList.length && (
            <>
              <DetailsTable 
                mobileTheme={DetailsTableMobileTheme.Dark}
                headers={[
                  {
                    name: TargetListColumnName.ID,
                    label: t('chic.management.goalDetailsView.detailsTable.id'),
                  },
                  {
                    name: TargetListColumnName.Name,
                    label: t('chic.management.goalDetailsView.detailsTable.name'),
                  },
                  {
                    name: TargetListColumnName.Award,
                    label: t('chic.management.goalDetailsView.detailsTable.award'),
                  },
                  {
                    name: TargetListColumnName.Realization,
                    label: t('chic.management.goalDetailsView.detailsTable.realization'),
                  },
                  { name: TargetListColumnName.Progress },
                ]} 
                tableData={targetsList}          
              />
              {pagesCount > 1 && (
                <StyledPagination 
                  activePage={page} 
                  pagesCount={pagesCount} 
                  onActivePageChange={setPage} 
                  colorTheme={ComponentColorTheme.IC} 
                />
              )}
            </>
          )}
        </Section>
      </InnerContainer>
    </BaseViewForGoals> 
  );
};
