export enum RoutingPath {
  // common paths
  Home = '/',
  ReturnFromCrm = '/login/crm',
  Dashboard = '/panel',
  DiscountCodesSearch = '/kody-rabatowe',
  DiscountCodeDetails = '/kody-rabatowe/:code',
  SignIn = '/logowanie-wewnetrzne',
  RemindPassword = '/przypomnij-haslo',
  ChangePassword = '/zmiana-hasla',
  CrmSignIn = '/logowanie',
  CrmSignInRedirect = '/zaloguj-przez-crm',
  Profile = '/profil',
  UserRoleVerificationList = '/weryfikacja-uzytkownikow',
  UserRoleVerification = '/weryfikacja-uzytkownikow/:userId',
  NotFound = '*',
  // custom paths for forks apps
  AdAdd = '/zarzadzanie-animacjami/:type/dodaj-animacje',
  AdAddScreen = '/zarzadzanie-animacjami/:type/dodaj-ekran/:screenType',
  AdEdit = '/zarzadzanie-animacjami/:type/edycja-animacji/:id',
  AdEditScreen = '/zarzadzanie-animacjami/:type/edycja-ekranu/:screenType/:id',
  AdsList = '/zarzadzanie-animacjami',
  RefundsList = '/zwroty',
  SubscriptionList = '/inspiration-plan',
  SubscriptionDetails = '/inspiration-plan/:subscriptionId',
  SubscriptionDetailsBasicData = '/inspiration-plan/:subscriptionId/szczegoly',
  SubscriptionDetailsAmendmentsHistory = '/inspiration-plan/:subscriptionId/aneksy',
  SubscriptionDetailsCurrentOrder = '/inspiration-plan/:subscriptionId/biezace-zamowienie',
  SubscriptionDetailsOrdersHistory = '/inspiration-plan/:subscriptionId/zamowienia',
  SubscriptionOrder = '/inspiration-plan/:subscriptionId/nowe-zamowienie',
  SubscriptionEditProducts = '/inspiration-plan/:subscriptionId/edycja-produktow',
  SubscriptionEditProductsSummary = '/inspiration-plan/:subscriptionId/edycja-produktow/podsumowanie',
  SubscriptionEditDelivery = '/inspiration-plan/:subscriptionId/edycja-dostawy',
  SubscriptionEditDeliveryPoint = '/inspiration-plan/:subscriptionId/edycja-dostawy/wybierz-punkt-odbioru',
  Goals = '/cele-pracownicze',
  GoalsList = '/cele-pracownicze/aktywne',
  GoalAdd = '/cele-pracownicze/dodaj',
  GoalEdit = '/cele-pracownicze/:goalId/edytuj',
  GoalDetails = '/cele-pracownicze/:goalId',
  GoalProductsGroupsList = '/cele-pracownicze/grupy-produktowe',
  GoalProductsGroupAdd = '/cele-pracownicze/grupy-produktowe/dodaj',
  GoalProductsGroupEdit = '/cele-pracownicze/grupy-produktowe/:productsGroupId/edytuj',
  GoalProductsGroupDetails = '/cele-pracownicze/grupy-produktowe/:productsGroupId',
}
