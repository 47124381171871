import React from 'react';
import { BaseViewForGoalsProps } from './baseViewForGoals.types';
import { Container, PageTitleWrapper } from './baseViewForGoals.styled';
import { PageTitle, PageTitleSize, ScrollToTopButton } from '@chic-loyalty/ui';

export const BaseViewForGoals: React.FC<BaseViewForGoalsProps> = (props: BaseViewForGoalsProps): JSX.Element => {
  const { pageTitle, pageTitleChildren, children, background, backgroundMobile, goBackAction }: BaseViewForGoalsProps = props;

  return (
    <Container $background={background} $backgroundMobile={backgroundMobile}>
      <PageTitleWrapper>
        <PageTitle label={pageTitle} size={PageTitleSize.Big} onClick={goBackAction} />
        {!!pageTitleChildren && <>{pageTitleChildren}</>}
      </PageTitleWrapper>
      {children}
      <ScrollToTopButton />
    </Container>
  );
};
