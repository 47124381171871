import { 
  ApiResolve, 
  ApiReject,
  GoalListElement,
  GoalDetails,
  GoalRequestData,
  GoalCsvParseUpload,
  GoalDetailsCsv,
  GoalProductsGroupDetails,
  GoalProductsGroupRequestData,
  GoalsDictionary, 
} from '@chic/models';
import { AxiosResponse } from 'axios';
import { api } from '../api.service';

export const getGoalsList: () => Promise<GoalListElement[]> = (): Promise<GoalListElement[]> => new Promise(
  (resolve: ApiResolve<GoalListElement[]>, reject: ApiReject): Promise<void> => (
    api.get('/management/goals')
      .then((response: AxiosResponse<GoalListElement[]>): void => resolve(response?.data))
      .catch(reject)
  ),
);

export const addNewGoal: (data: GoalRequestData) => Promise<GoalDetails> = (
  data: GoalRequestData,
): Promise<GoalDetails> => new Promise(
  (resolve: ApiResolve<GoalDetails>, reject: ApiReject): Promise<void> => (
    api.post('/management/goals', data)
      .then((response: AxiosResponse<GoalDetails>): void => resolve(response?.data))
      .catch(reject)
  ),
);

export const getGoalDetails: (id: number) => Promise<GoalDetails> = (id: number): Promise<GoalDetails> => new Promise(
  (resolve: ApiResolve<GoalDetails>, reject: ApiReject): Promise<void> => (
    api.get(`/management/goals/${id}`)
      .then((response: AxiosResponse<GoalDetails>): void => resolve(response?.data))
      .catch(reject)
  ),
);

export const deleteGoal: (id: number) => Promise<void> = (id: number): Promise<void> => new Promise(
  (resolve: ApiResolve<void>, reject: ApiReject): Promise<void> => (
    api.delete(`/management/goals/${id}`)
      .then((response: AxiosResponse<void>): void => resolve(response?.data))
      .catch(reject)
  ),
);

export const editGoal: (id: number, data: GoalRequestData) => Promise<GoalDetails> = (
  id: number, data: GoalRequestData,
): Promise<GoalDetails> => new Promise(
  (resolve: ApiResolve<GoalDetails>, reject: ApiReject): Promise<void> => (
    api.patch(`/management/goals/${id}`, data)
      .then((response: AxiosResponse<GoalDetails>): void => resolve(response?.data))
      .catch(reject)
  ),
);

export const parseGoalCsv: (data: GoalCsvParseUpload) => Promise<GoalDetailsCsv> = (
  data: GoalCsvParseUpload,
): Promise<GoalDetailsCsv> => new Promise(
  (resolve: ApiResolve<GoalDetailsCsv>, reject: ApiReject): Promise<void> => (
    // eslint-disable-next-line @typescript-eslint/naming-convention
    api.post('/management/goals/csv', data, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then((response: AxiosResponse<GoalDetailsCsv>): void => resolve(response?.data))
      .catch(reject)
  ),
);

export const getProductsGroupsList: () => Promise<GoalProductsGroupDetails[]> = (): Promise<GoalProductsGroupDetails[]> => new Promise(
  (resolve: ApiResolve<GoalProductsGroupDetails[]>, reject: ApiReject): Promise<void> => (
    api.get('/management/goals/groups')
      .then((response: AxiosResponse<GoalProductsGroupDetails[]>): void => resolve(response?.data))
      .catch(reject)
  ),
);

export const addNewProductsGroup: (data: GoalProductsGroupRequestData) => Promise<GoalProductsGroupDetails> = (
  data: GoalProductsGroupRequestData,
): Promise<GoalProductsGroupDetails> => new Promise(
  (resolve: ApiResolve<GoalProductsGroupDetails>, reject: ApiReject): Promise<void> => (
    api.post('/management/goals/groups', data)
      .then((response: AxiosResponse<GoalProductsGroupDetails>): void => resolve(response?.data))
      .catch(reject)
  ),
);

export const getProductsGroupDetails: (id: number) => Promise<GoalProductsGroupDetails> = (
  id: number,
): Promise<GoalProductsGroupDetails> => new Promise(
  (resolve: ApiResolve<GoalProductsGroupDetails>, reject: ApiReject): Promise<void> => (
    api.get(`/management/goals/groups/${id}`)
      .then((response: AxiosResponse<GoalProductsGroupDetails>): void => resolve(response?.data))
      .catch(reject)
  ),
);

export const deleteProductsGroup: (id: number) => Promise<void> = (
  id: number,
): Promise<void> => new Promise(
  (resolve: ApiResolve<void>, reject: ApiReject): Promise<void> => (
    api.delete(`/management/goals/groups/${id}`)
      .then((response: AxiosResponse<void>): void => resolve(response?.data))
      .catch(reject)
  ),
);

export const editProductsGroup: (id: number, data: GoalProductsGroupRequestData) => Promise<GoalProductsGroupDetails> = (
  id: number, data: GoalProductsGroupRequestData,
): Promise<GoalProductsGroupDetails> => new Promise(
  (resolve: ApiResolve<GoalProductsGroupDetails>, reject: ApiReject): Promise<void> => (
    api.patch(`/management/goals/groups/${id}`, data)
      .then((response: AxiosResponse<GoalProductsGroupDetails>): void => resolve(response?.data))
      .catch(reject)
  ),
);

export const getGoalsStatics: () => Promise<GoalsDictionary> = (): Promise<GoalsDictionary> => new Promise(
  (resolve: ApiResolve<GoalsDictionary>, reject: ApiReject): Promise<void> => (
    api.get('/management/goals/static')
      .then((response: AxiosResponse<GoalsDictionary>): void => resolve(response?.data))
      .catch(reject)
  ),
);
