import React from 'react';

import { TransProps, useTranslation } from 'react-i18next';
import { Breakpoint, Button, ButtonTheme, UseRedirect, useRedirect } from '@chic-loyalty/ui';
import { useMediaQuery } from 'react-responsive';
import { Code, Container, Info, InnerContainer } from './websiteError.styled';
import { WebsiteErrorProps } from './websiteError.types';

import BackgroundImage from './images/background.jpg';
import MobileImage from './images/mobile.jpg';
import { ConfigsContextType } from '@chic/models';
import { useConfig } from '@chic/hooks';

export const WebsiteError: React.FC<WebsiteErrorProps> = (props: WebsiteErrorProps): JSX.Element => {
  const { code, info, className }: WebsiteErrorProps = props;
  const { t }: TransProps<never> = useTranslation();
  const { redirect }: UseRedirect = useRedirect();
  const config: ConfigsContextType = useConfig();
  const isSmallMobile: boolean = useMediaQuery({ query: Breakpoint.SmallMobile });

  return (
    <Container className={className} $img={isSmallMobile ? MobileImage : BackgroundImage}>
      <InnerContainer>
        <Code>{code}</Code>
        <Info>{info}</Info>
        <Button
          label={t('chic.crmApp.websiteError.buttonLabel')}
          buttonTheme={ButtonTheme.Primary}
          onClick={(): void => redirect(config.defaultRoute)}
        />
      </InnerContainer>
    </Container>
  );
};
