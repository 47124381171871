import { SubscriptionPlanProductData, SubscriptionProductsGroup, UseSubscriptionDetailsContext } from '@chic/models';
import { TransProps, useTranslation } from 'react-i18next';
import { UseSubscriptionEditProductsSummary } from './subscriptionEditProductsSummary.types';
import { useNotifications, UseNotifications, UseRedirect, useRedirect } from '@chic-loyalty/ui';
import { FileFromViews, PopupId, RoutingPath, SubscriptionDeliveryType } from '@chic/enums';
import { useSubscriptionDetailsContext } from '@chic/hooks';
import { SubscriptionProductAmount } from '@chic/interfaces';
import { useSubscriptionDetailsActions, useSubscriptionDetailsData } from '../hooks';
import { UseSubscriptionDetailsActions, UseSubscriptionDetailsData } from '../types';

export const useSubscriptionEditProductsSummary: () => UseSubscriptionEditProductsSummary = (): UseSubscriptionEditProductsSummary => {
  const { t }: TransProps<never> = useTranslation();
  const { updateProducts }: UseSubscriptionDetailsActions = useSubscriptionDetailsActions();
  const { redirect }: UseRedirect = useRedirect();
  const { userEditProductsData, subscriptionDetails }: UseSubscriptionDetailsContext = useSubscriptionDetailsContext();
  const { 
    userSubscriptionPlan, 
    isProductAvailable, 
    userSubscriptionPlanProductsMap, 
  }: UseSubscriptionDetailsData = useSubscriptionDetailsData();
  const { showFullscreenAlert, hideFullscreenAlert, showSubscriptionPopup, hideSubscriptionPopup }: UseNotifications = useNotifications();

  const getRestrictedProductsFromSelectedProducts: () => SubscriptionPlanProductData[] = (): SubscriptionPlanProductData[] => {
    if (!userSubscriptionPlanProductsMap || !userEditProductsData?.productsDetails.length) {
      return [];
    }
    const restrictedProducts: SubscriptionPlanProductData[] = [];

    userEditProductsData.productsDetails.forEach((product: SubscriptionPlanProductData): void => {
      const foundProduct: SubscriptionPlanProductData | undefined = userSubscriptionPlanProductsMap.get(product.id);
        
      if (foundProduct?.restricted) {
        restrictedProducts.push(foundProduct);
      }
    });

    return restrictedProducts;
  };

  const getRestrictedProductsGroupsNames: () => string[] = (): string[] => {
    if (!userEditProductsData?.productsDetails.length 
        || !subscriptionDetails?.contract.delivery.type 
        || !userSubscriptionPlan?.productsGroups) {
      return [];
    }

    const names: string[] = [];
    userSubscriptionPlan.productsGroups.forEach((group: SubscriptionProductsGroup): void => {
      const foundRestrictedProduct: SubscriptionPlanProductData | undefined = group.products
        .find((groupProduct: SubscriptionPlanProductData): boolean => !!groupProduct.restricted && !!userEditProductsData.productsDetails
          .find((chosenProduct: SubscriptionPlanProductData): boolean => chosenProduct.id === groupProduct.id));

      if (foundRestrictedProduct) {
        names.push(group.name);
      }
    });
      
    return names;
  };

  const goBack: () => void = (): void => {
    if (!subscriptionDetails?.id) {
      return;
    }
    
    redirect(RoutingPath.SubscriptionEditProducts, { subscriptionId: subscriptionDetails.id });
  };

  const cancelProductsEdit: () => void = (): void => {
    if (!subscriptionDetails?.id) {
      return;
    }

    showFullscreenAlert({
      title: t('chic.management.useSubscriptionEditProductsSummary.cancelProductsEdit.title'),
      acceptButtonSettings: {
        label: t('chic.management.global.yes'),
        action: (): void => {
          redirect(RoutingPath.SubscriptionDetailsBasicData, { subscriptionId: subscriptionDetails.id });
          hideFullscreenAlert();
        },
      },
      cancelButtonSettings: {
        label: t('chic.management.global.no'),
        action: hideFullscreenAlert,
      },
    });
  };

  const onSaveProducts: () => void = (): void => {
    if (!subscriptionDetails?.id || !userEditProductsData || !userSubscriptionPlan) {
      return;
    }

    const restrictedProducts: SubscriptionPlanProductData[] = getRestrictedProductsFromSelectedProducts();
    const restictedProductsGroupsNames: string[] = getRestrictedProductsGroupsNames();
    const shouldChangeDelivery: boolean = !!(
      restrictedProducts.length && ![
        SubscriptionDeliveryType.DpdCourier,
        SubscriptionDeliveryType.DpdPickupPoint,
      ].includes(subscriptionDetails.contract.delivery.type)
    );

    if (shouldChangeDelivery) {
      showSubscriptionPopup({
        id: PopupId.SubscriptionPopupFreeDpdDelivery,
        header: t(
          'chic.management.useSubscriptionEditProductsSummary.subscriptionPopup.header', 
          { names: restictedProductsGroupsNames.join(', ') },
        ),
        buttonSettings: {
          label: t('chic.management.useSubscriptionEditProductsSummary.subscriptionPopup.buttonLabel'),
          action: (): void => {
            redirect(RoutingPath.SubscriptionEditDelivery, { subscriptionId: subscriptionDetails.id });
            hideSubscriptionPopup({ id: PopupId.SubscriptionPopupFreeDpdDelivery });
          },
        },
        closeIconAction: (): void => hideSubscriptionPopup({ id: PopupId.SubscriptionPopupFreeDpdDelivery }),
        backgroundImage: FileFromViews.SubscriptionPopupGlobalDesktop,
        mobileBackgroundImage: FileFromViews.SubscriptionPopupGlobalMobile,
      });
      
      return;
    }

    updateProducts(subscriptionDetails.id, {
      products: Object.entries(userEditProductsData.products).map((product: [string, number]): SubscriptionProductAmount => ({
        id: parseInt(product[0], 10),
        amount: product[1],
      })),
    });
  };

  return { goBack, cancelProductsEdit, onSaveProducts, isProductAvailable };
};
