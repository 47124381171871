import { GoalsAction } from '@chic/enums';
import { GoalsActions, GoalsState } from '@chic/models';

export const goalsReducer: (
  state: GoalsState, action: GoalsActions
) => GoalsState = (
  state: GoalsState, action: GoalsActions,
): GoalsState => {
  switch (action.type) {
    case GoalsAction.UpdateGoalsDictionary:
      return { ...state, goalsDictionary: action.payload };
    case GoalsAction.UpdateGoalDetails:
      return { ...state, goalDetails: action.payload };
    default: 
      return state;
  }
};
