export enum UserRoleVerificationListActions {
  SetSortKey = 'setSortKey',
  SetSortOrder = 'setSortOrder',
  SetFilterCrmIdValue = 'setFilterCrmIdValue',
  SetFilterNameValue = 'setFilterNameValue',
  SetFilterEmailValue = 'setFilterEmailValue',
}

export enum UserVerificationTableName {
  CrmId = 'crmId',
  FullName = 'fullName',
  Email = 'email',
  Verification = 'verification',
}
