import React from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { BaseViewForAnimations } from '@chic/components';
import { Params, useParams } from 'react-router-dom';
import { FileTypeExtended, AdScreenType, RoutingPath, SetupType, UploadAssetType, AnimationStateType, AddFileTabId } from '@chic/enums';
import { AddFileTheme, FileSettingsBox, FileSettingsBoxesTheme, Loader, useRedirect, UseRedirect } from '@chic-loyalty/ui';
import { ScreenNodeDetails, UseAnimations, UseAnimationsContext } from '@chic/models';
import { useAnimations, useAnimationsContext, useTitle } from '@chic/hooks';

export const AdAddScreenView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { type, screenType }: Params = useParams();
  const { redirect }: UseRedirect = useRedirect();
  const { 
    onAddFile, 
    onSaveFile, 
    onSetAnimationName, 
    requirements, 
    savedFiles, 
    onCreateNewScreen, 
    onDeleteSavedFile, 
    onSavedFilesChangePositions, 
    acceptedFilesTypes,
    fileToEdit,
    resetUploadedData,
    onAddNewItem,
    onEditFile,
    targetsForButtons,
    setupType,
    transformAssetDetailsToAddFileInitial,
    uploadErrorMessage,
    animationName,
    setUploadedFileSettings,
    isCreateNewScreenLoading,
    setOpenFileDialog,
  }: UseAnimations = useAnimations(
    type as SetupType, 
    screenType === AdScreenType.AnimationsSlider ? UploadAssetType.TVAnimation : UploadAssetType.TVButton,
    screenType as AdScreenType, 
  );
  const { animationData }: UseAnimationsContext = useAnimationsContext();
  useTitle(t('chic.management.adAddScreenView.title'));

  const goBackToPreviousPage: () => void = (): void => {
    if (!animationData.type) {
      return;
    }
    
    redirect(animationData.type === AnimationStateType.Create 
      ? RoutingPath.AdAdd 
      : RoutingPath.AdEdit, { 
      type: type ?? '',
      id: animationData.setupId ?? '',
    });
  };
  
  return (
    <>
      {!setupType ? <Loader /> : (
        <BaseViewForAnimations 
          title={t(`chic.management.adAddScreenView.${screenType}.title`)}
          goBack={goBackToPreviousPage}
          nameInputLabel={t('chic.management.adAddScreenView.nameInputLabel')}
          onNameChange={onSetAnimationName}
          addFileProps={{ 
            setOpenFileDialog,
            tabs: [
              {
                title: t('chic.management.global.addFile'),
                id: AddFileTabId.ScreenFile,
              },
            ],
            items: [
              {
                theme: screenType === AdScreenType.AnimationsSlider ? AddFileTheme.TVAd : AddFileTheme.TVButton,
                maxImageSizeInBytes: 2 * 1024 * 1024,
                maxVideoSizeInBytes: screenType === AdScreenType.AnimationsSlider ? 15 * 1024 * 1024 : undefined,
                addFile: onAddFile,
                requirements,
                acceptedFilesTypes,
                withDurationChange: screenType === AdScreenType.AnimationsSlider
                  ? 'conditionally'
                  : 'never',
                dropdownOptions: screenType === AdScreenType.CategoriesList ? targetsForButtons : undefined,
                resetData: resetUploadedData,
                initialData: transformAssetDetailsToAddFileInitial(fileToEdit),
                errorMessage: uploadErrorMessage,
                id: AddFileTabId.ScreenFile,
                onChange: setUploadedFileSettings,
              },
            ],
            saveFiles: onSaveFile,
          }}
          fileSettingsBoxesProps={{
            title: t(`chic.management.adAddScreenView.fileSettingsBoxesProps.${screenType}.title`),
            description: t(`chic.management.adAddScreenView.fileSettingsBoxesProps.${screenType}.description`),
            items: savedFiles.map((savedFile: ScreenNodeDetails): FileSettingsBox => ({
              name: savedFile.name,
              path: savedFile.value,
              isVideo: savedFile.type === FileTypeExtended.Video,
              id: savedFile.id,
            })),
            onAddNewItem,
            onItemDelete: onDeleteSavedFile,
            onItemEdit: onEditFile,
            onPositionChange: onSavedFilesChangePositions,
            theme: FileSettingsBoxesTheme.Horizontal,
            addNewButtonLabel: t(`chic.management.adAddScreenView.fileSettingsBoxesProps.${screenType}.addNewButtonLabel`),
          }}
          acceptButtonSettings={{
            label: t('chic.management.global.save'),
            action: onCreateNewScreen,
            isLoading: isCreateNewScreenLoading,
            disabled: !animationName || !savedFiles.length,
          }}
          cancelButtonSettings={{
            label: t('chic.management.global.cancel'),
            action: goBackToPreviousPage,
          }}
        />
      )}
    </>
  );
};
