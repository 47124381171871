import { useNotifications, UseNotifications, useRedirect, UseRedirect } from '@chic-loyalty/ui';
import { TransProps, useTranslation } from 'react-i18next';
import { getGoalDetails, getGoalsStatics } from '@chic/api';
import { GoalsContext, GoalsDispatchContext } from '@chic/contexts';
import { GoalsAction, QueryKey, RoutingPath } from '@chic/enums';
import { FrontendApiError, GoalDetails, GoalsActions, GoalsDictionary, GoalsState } from '@chic/models';
import { goalsReducer } from '@chic/reducers';
import { emptyRequest } from '@chic/utils';
import React, { Dispatch, useMemo, useReducer } from 'react';
import { useQuery } from 'react-query';
import { Outlet, Params, useParams } from 'react-router-dom';

export const GoalsProvider: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { goalId }: Params = useParams();
  const { redirect }: UseRedirect = useRedirect();
  const { addToast }: UseNotifications = useNotifications();
  const [goalsState, goalsDispatch]: [GoalsState, Dispatch<GoalsActions>] = useReducer(
    goalsReducer, 
    { 
      goalsDictionary: null,
      goalDetails: null,
    },
  );

  const checkedGoalId: number | null = useMemo(
    (): number | null => {
      const parsedId: number = parseInt(goalId ?? '', 10);

      return isNaN(parsedId)
        ? null
        : parsedId;
    },
    [goalId],
  );

  useQuery(
    [QueryKey.GoalDetails, checkedGoalId],
    (): Promise<GoalDetails | void> => checkedGoalId ? getGoalDetails(checkedGoalId) : emptyRequest(),
    {
      onSuccess: (data: GoalDetails | void): void => {
        goalsDispatch({ type: GoalsAction.UpdateGoalDetails, payload: data ?? null });
      },
      onError: (error: FrontendApiError): void => {
        addToast({ content: error.message ?? t('chic.management.goalsProvider.getGoalDetails.error') });
        redirect(RoutingPath.GoalsList);
      },
    },
  );

  useQuery(
    [QueryKey.GoalsStatics],
    (): Promise<GoalsDictionary> => getGoalsStatics(),
    {
      onSuccess: (data: GoalsDictionary): void => {
        goalsDispatch({ type: GoalsAction.UpdateGoalsDictionary, payload: data });
      },
    },
  );
  
  return (
    <GoalsDispatchContext.Provider value={goalsDispatch}>
      <GoalsContext.Provider value={goalsState}>
        <Outlet />
      </GoalsContext.Provider>
    </GoalsDispatchContext.Provider>
  );
};
