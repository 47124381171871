import React, { useMemo, useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { BaseViewForSubscriptionDetailsProps } from './baseViewForSubscriptionDetails.types';
import { 
  Breakpoint, 
  Button, 
  ButtonSettings, 
  ButtonTheme, 
  MenuPosition, 
  PageTitle, 
  PageTitleSize, 
  TabSwitchOption, 
  useRedirect, 
  UseRedirect, 
  UseState, 
} from '@chic-loyalty/ui';
import { 
  ButtonsWrapper, 
  ChildrenContainer, 
  Container, 
  InnerContainer, 
  PageTitleWrapper, 
  StyledTabSwitch, 
} from './baseViewForSubscriptionDetails.styled';
import { FileFromViews, RoutingPath, SessionStorage, SubscriptionDetailsPageName } from '@chic/enums';
import { useTitle } from '@chic/hooks';
import { useMediaQuery } from 'react-responsive';
import { stringify } from 'query-string';

export const BaseViewForSubscriptionDetails: React.FC<BaseViewForSubscriptionDetailsProps> = (
  props: BaseViewForSubscriptionDetailsProps,
): JSX.Element => {
  const { 
    activeTab, 
    backgroundImage = FileFromViews.BaseViewForSubscriptionDetailsBackground, 
    children,
    isNewOrderButtonActive,
    subscriptionId,
    bottomButtons,
  }: BaseViewForSubscriptionDetailsProps = props;
  const { t }: TransProps<never> = useTranslation();
  const { redirect }: UseRedirect = useRedirect();
  const [activeTabOption, setActiveTabOption]: UseState<string> = useState<string>(activeTab);
  const isMobile: boolean = useMediaQuery({ query: Breakpoint.Mobile });
  useTitle(t('chic.management.baseViewForSubscriptionDetails.title'));

  const tabs: TabSwitchOption[] = useMemo(
    (): TabSwitchOption[] => {
      return [
        {
          labels: [t('chic.management.baseViewForSubscriptionDetails.basicData')],
          name: SubscriptionDetailsPageName.BasicData,
        },
        {
          labels: [t('chic.management.baseViewForSubscriptionDetails.currentOrder')],
          name: SubscriptionDetailsPageName.CurrentOrder,
        },
        {
          labels: [t('chic.management.baseViewForSubscriptionDetails.ordersHistory')],
          name: SubscriptionDetailsPageName.OrdersHistory,
        },
        {
          labels: [t('chic.management.baseViewForSubscriptionDetails.amendmentsHistory')],
          name: SubscriptionDetailsPageName.AmendmentsHistory,
        },
      ];
    },
    [],
  );

  const onChangeActiveTab: (tab: string) => void = (tab: string): void => {
    if (activeTabOption === tab || !subscriptionId) {
      return;
    }

    setActiveTabOption(tab);
    
    switch (tab as SubscriptionDetailsPageName) {
      case SubscriptionDetailsPageName.BasicData: 
        redirect(RoutingPath.SubscriptionDetailsBasicData, { subscriptionId });
        break;
      case SubscriptionDetailsPageName.CurrentOrder: 
        redirect(RoutingPath.SubscriptionDetailsCurrentOrder, { subscriptionId });
        break;
      case SubscriptionDetailsPageName.AmendmentsHistory: 
        redirect(RoutingPath.SubscriptionDetailsAmendmentsHistory, { subscriptionId });
        break;
      case SubscriptionDetailsPageName.OrdersHistory: 
        redirect(RoutingPath.SubscriptionDetailsOrdersHistory, { subscriptionId });
        break;
    }
  };

  const goBack: () => void = (): void => {
    const subscriptionListFilters: string | null = sessionStorage.getItem(SessionStorage.SubscriptionListFilters);
    redirect(`${RoutingPath.SubscriptionList}?${stringify({ search: subscriptionListFilters }, { skipNull: true })}`);
  };

  const redirectToOrderCreate: () => void = (): void => {
    if (!subscriptionId) {
      return;
    }
    
    redirect(RoutingPath.SubscriptionOrder, { subscriptionId });
  };
  
  return (
    <Container $background={backgroundImage}>
      <PageTitleWrapper>
        <PageTitle 
          size={PageTitleSize.Big} 
          label={t('chic.management.baseViewForSubscriptionDetails.pageTitle')} 
          onClick={goBack}
        />
        {!!isNewOrderButtonActive && (
          <Button 
            label={t('chic.management.baseViewForSubscriptionDetails.newOrder')}
            onClick={redirectToOrderCreate}
            buttonTheme={ButtonTheme.Text}
          />
        )}
      </PageTitleWrapper>
      <InnerContainer>
        <StyledTabSwitch 
          tabs={tabs} 
          initialTabName={activeTabOption}
          onTabChange={onChangeActiveTab} 
          isSwipeable={isMobile}
        />
        <ChildrenContainer>
          {children}
        </ChildrenContainer>
      </InnerContainer>
      {!!bottomButtons?.length && (
        <ButtonsWrapper>
          {bottomButtons.map((button: ButtonSettings, index: number): JSX.Element => (
            <MenuPosition {...button}>
              <Button 
                label={button.label} 
                buttonTheme={button.buttonTheme} 
                disabled={button.disabled} 
                reverted={index === 0 && bottomButtons.length > 1}
              />
            </MenuPosition>
          ))}
        </ButtonsWrapper>
      )}
    </Container>
  );
};
